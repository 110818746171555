import {
  PAY_ORDER, GET_ORDERS, GET_ORDER, MAKE_ORDER, DISH_COUNT, ADD_DISH, GET_CHECKOUT_DISHES, REMOVE_ORDER_DISHES,
  WAITER_REQEST, SELECT_DISH_VARIATION, ADD_CUTLERY, CUTLERY_COUNT, RATE_DISH_FROM_ORDER, REMOVE_DISH_FROM_ORDER,
  CHANGE_ORDER_BULK_CHECKOUT, CHANGE_ORDER_CHECKOUT, ADD_DISH_TO_PAYSHARE, APPROVE_ORDER, SET_ORDER_MESSAGE,
  UPDATE_ORDER_MESSAGES, UPDATE_ACTIVE_ORDERS, SET_ACTIVE_ORDER, CHANGE_DISH_ORDER, REMOVE_ORDER_CATEGORY_DISHES,
  GET_GROUP_ORDERS
} from "../actions/OrderTypes";

let today = new Date();
today.setMinutes (today.getMinutes() + 61);

const initialState = {
  orders: {},
  order: '',
  orderDishes: {},
  total_pages: 1,
  currentPage: 1,
  approveOrder: false,
  orderCheckout: {
    selectedOrderType: 'to_visit',
    selectedPaymentType: '',
    time: today,
    description: '',
    selectedReadyType: 'when_ready',
    persons: 1,
    first_name: '',
    phone: null,
    cutleries: false,
    shareOrder: false,
    payShares: [{amount: '', dishes: {}, name: ''}, {amount: '', dishes: {}, name: ''}],
    selectedTable: {},
    selectedRoom: {},
    addTableToOrder: false,
    delivery_point: {lat: '', lng: ''},
    delivery_address: '',
    delivery_price: 0,
    delivery_cost: 0,
    tables: {},
    rooms: {},
    isCompany: false
  },
  activeOrders: {}
};

export default function OrdersReducer(state = initialState, actions) {
  if (actions.type === PAY_ORDER) {
    return {
      ...state,
      orders: actions.payload
    };
  }

  if (actions.type === MAKE_ORDER) {
    const orderDishes = { ...state.orderDishes };
    delete orderDishes[actions.payload.restaurant.slug];
    return {
      ...state,
      orderDishes: orderDishes
    };
  }

  if (actions.type === GET_ORDERS) {
    const orders = Object.assign(actions.payload, state.orders);
    return {
      ...state,
      orders: orders,
      total_pages: actions.total_pages,
      currentPage: actions.page
    }
  }

  if (actions.type === GET_GROUP_ORDERS) {
    const orders = {...state.orders, ...actions.payload};
    return {
      ...state,
      orders: orders
    }
  }

  if (actions.type === GET_ORDER) {
    return {
      ...state,
      orders: {
        ...state.orders,
        [actions.payload.id]: actions.payload
      },
      order: actions.payload.id
    };
  }

  if (actions.type === DISH_COUNT) {
    return {
      ...state,
      orderDishes: actions.payload
    };
  }

  if (actions.type === ADD_DISH) {
    return {
      ...state,
      orderDishes: {
        ...state.orderDishes,
        [actions.restaurant.slug]: actions.payload
      }
    };
  }

  if (actions.type === ADD_CUTLERY) {
    return {
      ...state,
      orderDishes: {
        ...state.orderDishes,
        [actions.restaurant.slug]: {
          ...state.orderDishes[actions.restaurant.slug],
          cutleryOrders: {
            ...state.orderDishes[actions.restaurant.slug].cutleryOrders,
            [actions.payload.id]: actions.payload
          }
        }
      }
    };
  }

  if (actions.type === CUTLERY_COUNT) {
    const newQuantity = state.orderDishes[actions.restaurant.slug].cutleryOrders[actions.payload].quantity + actions.value;
    if (newQuantity > 0) {
      return {
        ...state,
        orderDishes: {
          ...state.orderDishes,
          [actions.restaurant.slug]: {
            ...state.orderDishes[actions.restaurant.slug],
            cutleryOrders: {
              ...state.orderDishes[actions.restaurant.slug].cutleryOrders,
              [actions.payload]: {
                ...state.orderDishes[actions.restaurant.slug].cutleryOrders[actions.payload],
                quantity: newQuantity
              }
            }
          }
        }
      };
    } else {
      const cutleryOrders = {...state.orderDishes[actions.restaurant.slug].cutleryOrders};
      delete cutleryOrders[actions.payload];
      if (Object.keys(cutleryOrders).length > 0) {
        return {
          ...state,
          orderDishes: {
            ...state.orderDishes,
            [actions.restaurant.slug]: {
              ...state.orderDishes[actions.restaurant.slug],
              cutleryOrders: cutleryOrders
            }
          }
        };
      } else {
        return {
          ...state,
          orderDishes: {
            ...state.orderDishes,
            [actions.restaurant.slug]: {
              ...state.orderDishes[actions.restaurant.slug],
              cutleryOrders: {}
            }
          }
        };
      }
    }
  }

  if (actions.type === GET_CHECKOUT_DISHES) {
    return {
      ...state,
      orderDishes: actions.payload
    };
  }

  if (actions.type === REMOVE_ORDER_DISHES) {
    const updatedOrderDishes = { ...state.orderDishes };
    delete updatedOrderDishes[actions.payload];
    return {
      ...state,
      orderDishes: updatedOrderDishes,
    };
  }

  if (actions.type === REMOVE_ORDER_CATEGORY_DISHES) {
    return {
      ...state,
      orderDishes: {
        ...state.orderDishes,
        [actions.payload]: {
          ...state.orderDishes[actions.payload],
          dishes: actions.dishes
        }
      },
    };
  }

  if (actions.type === REMOVE_DISH_FROM_ORDER) {
    return {
      ...state,
      orderDishes: {
        ...state.orderDishes,
        [actions.restaurant.slug]: actions.payload
      }
    };
  }

  if (actions.type === CHANGE_ORDER_CHECKOUT) {
    return {
      ...state,
      orderCheckout: {
        ...state.orderCheckout,
        [actions.field]: actions.value
      }
    };
  }

  if (actions.type === CHANGE_ORDER_BULK_CHECKOUT) {
    let prevOrderCheckout = state.orderCheckout;
    const newOrderCheckout = Object.assign(prevOrderCheckout, actions.payload);
    return {
      ...state,
      orderCheckout: newOrderCheckout
    };
  }

  if (actions.type === WAITER_REQEST) {
    return {
      ...state,
      orders: {
        ...state.orders,
        [actions.orderId]: {
          ...state.orders[actions.orderId],
          table: actions.payload
        }
      }
    };
  }

  if (actions.type === SELECT_DISH_VARIATION) {
    return {
      ...state,
      orderDishes: actions.payload
    };
  }

  if (actions.type === RATE_DISH_FROM_ORDER) {
    return {
      ...state,
      orders: {
        ...state.orders,
        [state.order]: {
          ...state.orders[state.order],
          dishes: {
            ...state.orders[state.order].dishes,
            [actions.payload]: {
              ...state.orders[state.order].dishes[actions.payload],
              likes: actions.likes,
              dislikes: actions.dislikes
            }
          }
        }
      }
    };
  }

  if (actions.type === ADD_DISH_TO_PAYSHARE) {
    return {
      ...state,
      orderCheckout: {
        ...state.orderCheckout,
        payShares: actions.payload
      }
    };
  }

  if (actions.type === APPROVE_ORDER) {
    return {
      ...state,
      approveOrder: true
    };
  }

  if (actions.type === SET_ORDER_MESSAGE) {
    return {
      ...state,
      activeOrders: {
        ...state.activeOrders,
        [actions.orderId]: {
          ...state.activeOrders[actions.orderId],
          messages: {
            ...state.activeOrders[actions.orderId].messages,
            [actions.payload.id]: actions.payload
          }
        }

      }
    };
  }

  if (actions.type === UPDATE_ORDER_MESSAGES) {
    return {
      ...state,
      activeOrders: {
        ...state.activeOrders,
        [actions.order]: {
          ...state.activeOrders[actions.order],
          messages: actions.payload
        }
      }
    };
  }

  if (actions.type === UPDATE_ACTIVE_ORDERS) {
    return {
      ...state,
      activeOrders: actions.payload
    };
  }

  if (actions.type === SET_ACTIVE_ORDER) {
    const order = {...actions.payload};
    delete order['restaurant'];
    return {
      ...state,
      activeOrders: {
        ...state.activeOrders,
        [order.id]: order
      }
    };
  }

  if (actions.type === CHANGE_DISH_ORDER) {
    return {
      ...state,
      orders: {
        ...state.orders,
        [actions.order.id]: {
          ...state.orders[actions.order.id],
          dishes: {
            ...state.orders[actions.order.id].dishes,
            [actions.payload.dish_order_id]: actions.payload
          }
        }
      }
    };
  }

  return state;
}
