export const GET_ADMIN_ORDERS = '[Get Admin Orders Action] Get Admin Orders';
export const GET_ADMIN_ORDER = '[GET_ADMIN_ORDER Action] GET_ADMIN_ORDER';
export const GET_SETTINGS_1 = '[Get Settings 1 Action] Get Settings 1';
export const UPDATE_DASHBOARD_LIST = '[UPDATE_DASHBOARD_LIST Action] UPDATE_DASHBOARD_LIST';
export const GET_DELIVERY_ORDERS = '[GET_DELIVERY_ORDERS Action] GET_DELIVERY_ORDERS';
export const GET_DELIVERY_ORDER = '[GET_DELIVERY_ORDER Action] GET_DELIVERY_ORDER';
export const GET_MY_DELIVERY_ORDERS = '[GET_MY_DELIVERY_ORDERS Action] GET_MY_DELIVERY_ORDERS';
export const GET_RESTAURANT_ORDERS = '[GET_RESTAURANT_ORDERS Action] GET_RESTAURANT_ORDERS';
export const DELIVERY_TAKE_ORDER = '[DELIVERY_TAKE_ORDER Action] DELIVERY_TAKE_ORDER';
export const UPDATE_DELIVERY_LIST = '[UPDATE_DELIVERY_LIST Action] UPDATE_DELIVERY_LIST';
export const HANDLE_WAITER_REQUEST = '[HANDLE_WAITER_REQUEST Action] HANDLE_WAITER_REQUEST';
export const SAVE_PAY_CHECK = '[SAVE_PAY_CHECK Action] SAVE_PAY_CHECK';
export const DELETE_PAY_CHECK = '[DELETE_PAY_CHECK Action] DELETE_PAY_CHECK';
export const GET_PAY_CHECKS = '[GET_PAY_CHECKS Action] GET_PAY_CHECKS';
export const HANDLE_TABLES_DASHBOARD = '[HANDLE_TABLES_DASHBOARD Action] HANDLE_TABLES_DASHBOARD';
export const CREATE_ORDER_MESSAGE = '[CREATE_ORDER_MESSAGE Action] CREATE_ORDER_MESSAGE';
export const ORDER_MESSAGES_SEEN = '[ORDER_MESSAGES_SEEN Action] ORDER_MESSAGES_SEEN';
export const ADD_DISH_STOP_LIST = '[ADD_DISH_STOP_LIST Action] ADD_DISH_STOP_LIST';
export const HANDLE_TABLE_DASHBOARD = '[HANDLE_TABLE_DASHBOARD Action] HANDLE_TABLE_DASHBOARD';
export const GET_RESTAURANT_PAY_CHECK = '[GET_RESTAURANT_PAY_CHECK Action] GET_RESTAURANT_PAY_CHECK';
export const GET_MANAGER_ORDERS = '[GET_MANAGER_ORDERS Action] GET_MANAGER_ORDERS';
export const GET_MANAGER_ORDER = '[GET_MANAGER_ORDER Action] GET_MANAGER_ORDER';