import axiosInstance from "./AxiosInstance";

export function getOrders(page) {
  return axiosInstance.get('/api/orderings', {params: {page: page}});
}

export function getOrder(id, token) {
  return axiosInstance.get(`/api/orderings/${id}`, { params: {share_token: token} });
}

export function getGroupOrders(id) {
  return axiosInstance.get(`/api/orderings/${id}/group_orders`);
}

export function getDeliveryPrice(params) {
  return axiosInstance.get(`/api/calculate_delivery_price`, { params: params });
}

// export function getPackingPrice(params) {
//   const userDetails = JSON.parse(localStorage.getItem('userDetails'));
//   let config = null;
//   if (!!userDetails && !!userDetails.idToken) {
//     config = {
//       headers: {
//         'access-token': userDetails.idToken,
//         client: userDetails.client,
//         uid: userDetails.uid,
//       },
//       params: params
//     };
//   } else {
//     config = { params: params };
//   }
//   return axios.get(`${process.env.REACT_APP_API_URL}/api/calculate_packing_price`, config);
// }

export function makeOrder(restaurant_id, dishes, params, payMethod) {
  const payShares = !!params.payShares && params.payShares.map((ps) => {
    const dishes = Object.values(ps.dishes).map((d) => {
      if (!!d.selectedVariation) {
        return {id: d.id, quantity: d.quantity, dish_variation_id: d.selectedVariation.id}
      } else {
        return {id: d.id, quantity: d.quantity}
      }
    });
    return {amount: ps.amount, dishes: dishes, name: ps.name}
  });
  const orders = dishes.map((dish) => {
    const mods = Object.values(!!dish.selectedMods ? dish.selectedMods : {});
    if (!!dish.selectedVariation) {
      return {id: dish.id, quantity: dish.quantity, dish_variation_id: dish.selectedVariation.id, mods: mods}
    } else {
      return {id: dish.id, quantity: dish.quantity, mods: mods}
    }
  });
  const tableId = params.selectedOrderType === 'on_table' ? params.table_id :
    (params.selectedOrderType === 'to_visit' && params.addTableToOrder ? params.selectedTable.id : null);
  const postData = { restaurant_id, orders: orders, order_type: params.selectedOrderType,
    payment_type: payMethod || params.selectedPaymentType, time: new Date(params.time).getTime()/1000,
    description: params.description, when_ready: params.selectedReadyType === 'when_ready',
    persons: params.selectedOrderType === 'to_visit' ? params.persons : null,
    table_id: tableId,
    first_name: params.first_name,
    last_name: params.last_name,
    email: params.email,
    telegram_id: params.telegram_id,
    apple_id: params.apple_id,
    phone: params.phone,
    cutleries: params.cutleries,
    shared: params.shareOrder,
    after_pay_method: params.after_pay_method || 'no_method',
  };
  if (params.selectedOrderType === 'delivery') {
    Object.assign(postData, {delivery_point: params.delivery_point, delivery_address: params.delivery_address})
  }
  if (params.shareOrder) {
    Object.assign(postData, {pay_shares: payShares})
  }
  if (!!params.qr_id) {
    Object.assign(postData, {qr_id: params.qr_id})
  }
  if (!!params.theme) {
    Object.assign(postData, {theme: params.theme})
  }
  return axiosInstance.post(`/api/orderings`, postData);
}

export function makeBulkOrder(dishes, params) {
  let orders = {};
  Object.keys(dishes).map((rId) => {
    orders[rId] = dishes[rId].map((d) => {
      const mods = Object.values(!!d.selectedMods ? d.selectedMods : {});
      if (!!d.selectedVariation) {
        return {id: d.id, quantity: d.quantity, dish_variation_id: d.selectedVariation.id, mods: mods}
      } else {
        return {id: d.id, quantity: d.quantity, mods: mods}
      }
    });
  });
  const tableId = params.selectedOrderType === 'on_table' ? params.table_id :
    (params.selectedOrderType === 'to_visit' && params.addTableToOrder ? params.selectedTable.id : null);
  const postData = { orders: orders, order_type: params.selectedOrderType,
    payment_type: params.selectedPaymentType, time: new Date(params.time).getTime()/1000,
    description: params.description, when_ready: params.selectedReadyType === 'when_ready',
    persons: params.selectedOrderType !== 'to_go' ? params.persons : null,
    table_id: tableId,
    first_name: params.first_name,
    phone: params.phone,
    after_pay_method: params.after_pay_method || 'no_method',
    parent_restaurant_id: params.parent_restaurant_id
  };
  if (!!params.qr_id) {
    Object.assign(postData, {qr_id: params.qr_id})
  }
  if (!!params.theme) {
    Object.assign(postData, {theme: params.theme})
  }
  if (params.selectedOrderType === 'delivery') {
    Object.assign(postData, {delivery_point: params.delivery_point, delivery_address: params.delivery_address})
  }
  return axiosInstance.post(`/api/orderings/bulk_create`, postData);
}

export function payPayCheck(restaurant, payCheck) {
  const postData = { restaurant_id: restaurant.id, pay_check_id: payCheck.id, table_id: restaurant.table.id};
  return axiosInstance.post(`/api/payments`, postData);
}

export function checkInvoiceStatus(id) {
  return axiosInstance.get(`/api/payments/order_status`, {params: {order_id: id}});
}

export function checkPayShareInvoiceStatus(order, payShare) {
  return axiosInstance.get(`/api/payments/pay_share_status`,
    { params: {pay_share_id: payShare.id, order_id: order.id}});
}

export function updateOrder(parameters, order) {
  return axiosInstance.put(`/api/orderings/${order.id}`, {ordering: parameters});
}

export function refundOrder(order) {
  return axiosInstance.put(`/api/orderings/${order.id}/refund`);
}

export function cancelOrder(id) {
  return axiosInstance.delete(`/api/orderings/${id}`);
}

export function cancelBulkOrder(ids) {
  return axiosInstance.delete(`/api/orderings/bulk_cancel?ids=[${ids}]`);
}

export function updateOrderMessage(order) {
  return axiosInstance.put(`/api/order_messages/${order}`,);
}

export function getActiveOrders() {
  return axiosInstance.get(`/api/orderings/active_orders`);
}

export function saveOrderDishesToLocalStorage(restaurant) {
  let old = localStorage.getItem('checkoutDishes');
  if(old === null) {
    old = {};
  } else {
    old = JSON.parse(old);
  }
  localStorage.setItem('checkoutDishes', JSON.stringify(Object.assign(old,
    {[restaurant.restaurant.slug]: {restaurant: restaurant.restaurant, dishes: restaurant.dishes, company: restaurant.company}})));
}

export function removeDishesFromLocalStorage(slug) {
  let old = localStorage.getItem('checkoutDishes');
  if (old !== null) {
    old = JSON.parse(old);
    delete old[slug];
    localStorage.setItem('checkoutDishes', JSON.stringify(old));
  }
}

export function removeCategoryDishesFromLocalStorage(slug, dishes) {
  let old = localStorage.getItem('checkoutDishes');
  if (old !== null) {
    old = JSON.parse(old);
    old[slug].dishes = dishes;
    localStorage.setItem('checkoutDishes', JSON.stringify(old));
  }
}