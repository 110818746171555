import React from 'react';
import { QrReader } from 'react-qr-reader';
import {handleError} from "../../services/AlertService";
import {saveProfileInLocalStorage, submitShift} from "../../services/AuthService";
import swal from "sweetalert";
import { useTranslation } from 'react-i18next';

const QRcodeReader = (props) => {
  const { t } = useTranslation();

  function toggleWorking(shift) {
    submitShift(shift).then((response) => {
      saveProfileInLocalStorage(response.data.profile);
      swal('Gotovo', `Зміну ${ shift ? 'прийнято' : 'закрито'}`, "success");
    }).catch((error) => {
      handleError(error.response.data.errors, error.response);
    });
  }

  function handleScan(data) {
    if (!!data) {
      if (data.text === 'shift') {
        toggleWorking(true)
      } else if (data.text === 'unshift') {
        toggleWorking(false)
      } else if (!!props.action) {
        if (props.checking.includes(data.text)) {
          props.action()
        } else {
          handleError(['Неправильний QR код'])
        }
      } else {
        window.location.href = data
      }
    }
  }

  return (
    <div>
      <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
        <QrReader
          onResult={(result) => {
            if (!!result) {
              handleScan(result)
            }
          }}
          constraints={{ facingMode: 'environment' }}
        />
      </div>
    </div>
  );
};

export default QRcodeReader;