import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
import ThemeContext  from "./context/ThemeContext";
import ActionCable from 'actioncable';
import { ActionCableProvider } from 'react-actioncable-provider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-photo-view/dist/react-photo-view.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n';
const cable = ActionCable.createConsumer(process.env.REACT_APP_API_WS_URL);

ReactDOM.render(
  <ActionCableProvider cable={cable}>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter basename='/'>
            <ThemeContext store={store}>
                <ToastContainer position="top-right"/>
                <I18nextProvider i18n={i18n} key={i18n.language}>
                  <App />
                </I18nextProvider>
            </ThemeContext>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ActionCableProvider>,
    document.getElementById("root")
);
reportWebVitals();
