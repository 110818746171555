import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import Logo2 from "../../images/logo2.png";

const Loading = (props) => {
  const logos = [
    <img className="sk-child" src={Logo} alt="logo1" key={`i-${props.kind}-1`}/>,
    <img className="sk-child" src={Logo2} alt="logo2" key={`i-${props.kind}-2`}/>
  ];

  const [currentLogoIndex, setCurrentLogoIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLogoIndex((prevIndex) => prevIndex === 0 ? 1 : 0);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const currentLogo = logos[currentLogoIndex];

  if (props.whole) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">{currentLogo}</div>
      </div>
    );
  } else {
    return (
      <div className="sk-three-bounce" key={`b-${props.kind}`}>{currentLogo}</div>
    );
  }
};

export default Loading;
