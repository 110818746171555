export const PAY_ORDER = '[Pay Order Action] Pay Order';
export const MAKE_ORDER = '[Make Order Action] Make Order';
export const GET_ORDERS = '[Get Orders Action] Get Orders';
export const GET_ORDER = '[Get Order Action] Get Order';
export const DISH_COUNT = '[DISH_COUNT Action] DISH_COUNT';
export const ADD_DISH = '[ADD_DISH Action] ADD_DISH';
export const GET_CHECKOUT_DISHES = '[GET_CHECKOUT_DISHES Action] GET_CHECKOUT_DISHES';
export const REMOVE_ORDER_DISHES = '[REMOVE_ORDER_DISHES Action] REMOVE_ORDER_DISHES';
export const WAITER_REQEST = '[WAITER_REQEST Action] WAITER_REQEST';
export const SELECT_DISH_VARIATION = '[SELECT_DISH_VARIATION Action] SELECT_DISH_VARIATION';
export const ADD_CUTLERY = '[ADD_CUTLERY Action] ADD_CUTLERY';
export const CUTLERY_COUNT = '[CUTLERY_COUNT Action] CUTLERY_COUNT';
export const RATE_DISH_FROM_ORDER = '[RATE_DISH_FROM_ORDER Action] RATE_DISH_FROM_ORDER';
export const REMOVE_DISH_FROM_ORDER = '[REMOVE_DISH_FROM_ORDER Action] REMOVE_DISH_FROM_ORDER';
export const CHANGE_ORDER_BULK_CHECKOUT = '[CHANGE_ORDER_BULK_CHECKOUT Action] CHANGE_ORDER_BULK_CHECKOUT';
export const CHANGE_ORDER_CHECKOUT = '[CHANGE_ORDER_CHECKOUT Action] CHANGE_ORDER_CHECKOUT';
export const ADD_DISH_TO_PAYSHARE = '[ADD_DISH_TO_PAYSHARE Action] ADD_DISH_TO_PAYSHARE';
export const APPROVE_ORDER = '[APPROVE_ORDER Action] APPROVE_ORDER';
export const SET_ORDER_MESSAGE = '[SET_ORDER_MESSAGE Action] SET_ORDER_MESSAGE';
export const CREATE_ORDER_MESSAGE = '[CREATE_ORDER_MESSAGE Action] CREATE_ORDER_MESSAGE';
export const UPDATE_ORDER_MESSAGES = '[UPDATE_ORDER_MESSAGES Action] UPDATE_ORDER_MESSAGES';
export const UPDATE_ACTIVE_ORDERS = '[UPDATE_ACTIVE_ORDERS Action] UPDATE_ACTIVE_ORDERS';
export const SET_ACTIVE_ORDER = '[SET_ACTIVE_ORDER Action] SET_ACTIVE_ORDER';
export const CHANGE_DISH_ORDER = '[CHANGE_DISH_ORDER Action] CHANGE_DISH_ORDER';
export const REMOVE_ORDER_CATEGORY_DISHES = '[REMOVE_ORDER_CATEGORY_DISHES Action] REMOVE_ORDER_CATEGORY_DISHES';
export const GET_GROUP_ORDERS = '[GET_GROUP_ORDERS Action] GET_GROUP_ORDERS';