import herePolyline from '@liberty-rider/flexpolyline';

import {
  formatError, formatReviews, createReview, getRestaurant, getRestaurants,
  getMenu, getRestaurantReviews, getRestaurantsInArea, requestWaiter, divideCheck,
  getPayCheck, getRestaurantImages, getCategoryDishes, saveRateDish,
  getRestaurantsCategories, getRestaurantsInBounds, getCompany, getCategory
} from '../../services/RestaurantService';

import {
  GET_RESTAURANT, GET_RESTAURANTS, CREATE_REVIEW, REVIEW_FAILED_ACTION, GET_RESTAURANTS_WITH_ROUTES, GET_DISHES,
  SET_POINT, GET_REVIEWS, REQUEST_WAITER, DIVIDE_PAYCHECK, GET_PAYCHECK, GET_IMAGES, GET_CATEGORY_DISHES, RATE_DISH,
  SELECT_RESTAURANT, GET_RESTAURANTS_CATEGORIES, SELECT_CATEGORY, SORT_RESTAURANTS, GET_ROUTE_CATEGORIES, SET_MAP_VIEW,
  GET_COMPANIES, GET_COMPANY, GET_CATEGORY
} from './RestaurantTypes';
import {handleAlert, handleError} from "../../services/AlertService";
import {confirmedRequestWaiterFromOrderAction} from "./OrdersActions";

export function setPointAction(kind, location, history) {
  return {
    type: SET_POINT,
    payload: {kind: kind, location: location}
  };
}

export function getRestaurantsInAreaAction(latitude, longitude, radius) {
  return (dispatch) => {
    return getRestaurantsInArea(latitude, longitude, radius).then((response) => {
      dispatch(confirmedGetRestaurantsAction(response.data.restaurants, response.data.location));
      if (!!response.data.companies && Object.keys(response.data.companies).length > 0) {
        dispatch(confirmedGetCompaniesAction(response.data.companies));
      }
      return response.data.restaurants;
    });
  };
}

export function getCompanyAction(slug) {
  return (dispatch) => {
    return getCompany(slug).then((response) => {
      dispatch(confirmedGetCompanyAction(response.data.company));
      const location = [response.data.company.latitude, response.data.company.longitude];
      dispatch(confirmedGetRestaurantsAction(response.data.company.restaurants, location));
      dispatch(confirmedGetRestaurantsCategories(response.data.company.categories));
      return response.data.company;
    });
  };
}

export function getRestaurantsInBoundsAction(paramBounds, bounds, allMapScanned) {
  return (dispatch) => {
    return getRestaurantsInBounds(paramBounds).then((response) => {
      dispatch(confirmedGetRestaurantsAction(response.data.restaurants, response.data.location, bounds, allMapScanned));
      if (!!response.data.companies && Object.keys(response.data.companies).length > 0) {
        dispatch(confirmedGetCompaniesAction(response.data.companies));
      }
      return response.data.restaurants;
    });
  };
}

export function getRouteRestaurantsAction(startPoint, endPoint, history) {
  return (dispatch) => {
    return getRestaurants(startPoint, endPoint).then((response) => {
      let decodedRoutes = [];
      !!response.data.routes && response.data.routes.map((route) => {
        let converted = [];
        let decoded = herePolyline.decode(route.polyline);
        decoded.polyline.map((coords) => {converted.push({lat: coords[0], lng: coords[1]})});
        decodedRoutes.push(converted);
      });
      dispatch(confirmedGetRestaurantsWithRoutesAction(response.data.restaurants, decodedRoutes));
      return response.data.restaurants;
    });
  };
}

export function getRestaurantAction(restaurantSlug, table_id, fromMenu) {
  return (dispatch) => {
    return getRestaurant(restaurantSlug, table_id, fromMenu).then((response) => {
      dispatch(confirmedGetRestaurantAction(response.data.restaurant));
      return response.data.restaurant;
    })
    //   .catch(() => {
    //   window.location.href = (`/restaurants/${restaurantSlug}/menu`);
    // });
  };
}

export function requestWaiterAction(restaurant, checkRequest, type, table, waiterRequestText, orderId) {
  return (dispatch) => {
    return requestWaiter(restaurant.slug, !!table ? table.id : restaurant.table.id, checkRequest, type, waiterRequestText).then((response) => {
      if (!orderId) {
        dispatch(confirmedRequestWaiterAction(restaurant, response.data.table));
      } else {
        dispatch(confirmedRequestWaiterFromOrderAction(response.data.table, orderId));
      }
      if (checkRequest && type === 'qr') {
        if (!!response.data.table && !!response.data.table.pay_check) {
          handleAlert('default', 'Ваш рахунок', `${response.data.table.pay_check.amount}₴`)
        } else {
          handleAlert('default', 'Смачного', 'Офіціант перевірить суму до сплати і виставить вам сюди рахунок')
        }
      } else {
        handleAlert('default', 'Смачного', 'Офіціант скоро підійде')
      }
      return response.data.table;
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function divideCheckAction(payChecks, restaurant) {
  return (dispatch) => {
    return divideCheck(payChecks, restaurant.table.id).then((response) => {
      dispatch(confirmedDivideCheckAction(response.data.pay_checks, restaurant));
    }).catch((error) => {
      dispatch(confirmedDivideCheckAction(error.response.data.pay_checks, restaurant));
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function getPayCheckAction(restaurant) {
  return (dispatch) => {
    return getPayCheck(restaurant).then((response) => {
      dispatch(confirmedGetPayChecksAction(response.data.table, restaurant));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function getRestaurantReviewsAction(restaurant, history) {
  return (dispatch) => {
    getRestaurantReviews(restaurant).then((response) => {
      const reviews = formatReviews(response.data);
      dispatch(confirmedGetRestaurantReviewsAction(reviews, restaurant));
    })
  };
}

export function getRestaurantImagesAction(restaurant, history) {
  return (dispatch) => {
    getRestaurantImages(restaurant).then((response) => {
      dispatch(confirmedGetRestaurantImagesAction(response.data.images, restaurant));
    })
  };
}

export function createReviewAction(restaurant, comment, rating, history) {
  return (dispatch) => {
    createReview(restaurant.id, comment, rating).then((response) => {
      handleAlert('success', 'Дякуємо', 'Відгук додано')
      const reviews = formatReviews(response.data);
      dispatch(confirmedCreateReviewAction(reviews, restaurant));
    }).catch((error) => {
      const errorMessage = formatError(error.response.data);
      dispatch(reviewFailedAction(errorMessage));
    });
  };
}

export function getMenuAction(restaurant) {
  return (dispatch) => {
    return getMenu(restaurant.id).then((response) => {
      dispatch(confirmedGetMenuAction(response.data.dishes, restaurant));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function getCategoryDishesAction(restaurant, category) {
  return (dispatch) => {
    return getCategoryDishes(restaurant, category, true).then((response) => {
      dispatch(confirmedGetCategoryDishesAction(restaurant, category, response.data.dishes, response.data.photos_loaded));
    })
  };
}

export function getCategoryAction(company, restaurant, categoryType, category) {
  return (dispatch) => {
    return getCategory(restaurant, category).then((response) => {
      dispatch(confirmedGetCategoryAction(company, restaurant, categoryType, response.data.category));
    })
  };
}

export function rateDishAction(restaurant, category, dish, kind, dishIndex) {
  return (dispatch) => {
    return saveRateDish(dish, kind).then((response) => {
      dispatch(confirmedRateDishAction(restaurant, category, dish, dishIndex, response.data.likes,
        response.data.dislikes));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function getRestaurantsCategoriesAction(restaurants) {
  return (dispatch) => {
    const params = Object.values(restaurants).map((r) => r.id);
    return getRestaurantsCategories(params).then((response) => {
      dispatch(confirmedGetRestaurantsCategories(response.data.categories));
      return response.data.categories;
    })
    //   .catch((error) => {
    //   handleError(error.response.data.errors, error.response)
    // });
  };
}

export function getRouteCategoriesAction(restaurants) {
  return (dispatch) => {
    const params = Object.values(restaurants).map((r) => r.id);
    getRestaurantsCategories(params).then((response) => {
      dispatch(confirmedGetRouteCategories(response.data.categories));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function confirmedGetRestaurantsCategories(categories) {
  return {
    type: GET_RESTAURANTS_CATEGORIES,
    payload: categories,
  };
}

export function confirmedGetRouteCategories(categories) {
  return {
    type: GET_ROUTE_CATEGORIES,
    payload: categories,
  };
}

export function selectRestaurantAction(restaurant) {
  return {
    type: SELECT_RESTAURANT,
    payload: restaurant,
  };
}

export function sortAction(kind) {
  return {
    type: SORT_RESTAURANTS,
    payload: kind,
    then: (callback) => {
      callback(kind);
    }
  };
}

export function selectCategoryAction(category) {
  return {
    type: SELECT_CATEGORY,
    payload: category,
    then: (callback) => {
      callback(category);
    },
  };
}

export function confirmedRateDishAction(restaurant, category, dish, dishIndex, likes, dislikes) {
  return {
    type: RATE_DISH,
    restaurant: restaurant,
    category: category,
    payload: dish,
    dishIndex: dishIndex,
    likes: likes,
    dislikes: dislikes,
  };
}

export function confirmedGetRestaurantAction(restaurant) {
  return {
    type: GET_RESTAURANT,
    payload: restaurant,
  };
}

export function confirmedGetRestaurantsAction(restaurants, location, bounds, allMapScanned) {
  return {
    type: GET_RESTAURANTS,
    payload: restaurants,
    location: location,
    allMapScanned: allMapScanned,
    bounds: bounds
  };
}

function confirmedGetCompaniesAction(companies) {
  return {
    type: GET_COMPANIES,
    payload: companies
  };
}

function confirmedGetCompanyAction(company) {
  return {
    type: GET_COMPANY,
    payload: company
  };
}

export function confirmedGetRestaurantsWithRoutesAction(restaurants, routes) {
  return {
    type: GET_RESTAURANTS_WITH_ROUTES,
    payload: restaurants,
    routes: routes
  };
}

export function confirmedCreateReviewAction(reviews, restaurant) {
  return {
    type: CREATE_REVIEW,
    payload: reviews,
    restaurant: restaurant
  };
}

export function reviewFailedAction(message) {
  return {
    type: REVIEW_FAILED_ACTION,
    payload: message,
  };
}

export function confirmedGetMenuAction(dishes, restaurant) {
  return {
    type: GET_DISHES,
    payload: dishes,
    restaurant: restaurant
  };
}

export function confirmedGetRestaurantReviewsAction(reviews, restaurant) {
  return {
    type: GET_REVIEWS,
    payload: reviews,
    restaurant: restaurant
  };
}

export function confirmedRequestWaiterAction(restaurant, table) {
  return {
    type: REQUEST_WAITER,
    payload: table,
    restaurant: restaurant
  };
}

export function confirmedDivideCheckAction(payChecks, restaurant) {
  return {
    type: DIVIDE_PAYCHECK,
    payload: payChecks,
    restaurant: restaurant
  };
}

export function confirmedGetPayChecksAction(table, restaurant) {
  return {
    type: GET_PAYCHECK,
    payload: table,
    restaurant: restaurant
  };
}

export function confirmedGetRestaurantImagesAction(images, restaurant) {
  return {
    type: GET_IMAGES,
    payload: images,
    restaurant: restaurant
  };
}

export function confirmedGetCategoryDishesAction(restaurant, category, categoryDishes, photosLoaded) {
  return {
    type: GET_CATEGORY_DISHES,
    payload: categoryDishes,
    restaurant: restaurant,
    category: Object.assign(category, {photos_loaded: photosLoaded})
  };
}

function confirmedGetCategoryAction(company, restaurant, categoryType, category) {
  return {
    type: GET_CATEGORY,
    payload: category,
    restaurant: restaurant,
    company: company,
    categoryType: categoryType,
    category: Object.assign(category, {photos_loaded: true})
  };
}

export function setMapViewAction(center, zoom) {
  return {
    type: SET_MAP_VIEW,
    payload: center,
    zoom: zoom
  };
}