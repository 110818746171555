import axios from 'axios';
//import {shouldAskOrdersInRequest} from "./AuthService";
import {updateActiveOrdersAction} from "../store/actions/OrdersActions";
import {store} from '../store/store';

const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL});

axiosInstance.interceptors.request.use((config) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  config.headers['X-API-Key'] = process.env.REACT_APP_GOTOVO_API_KEY;
  if (!!userDetails && !!userDetails.idToken) {
    if (!config.removeHeaders) {
      const headers = {'access-token': userDetails.idToken, client: userDetails.client, uid: userDetails.uid};
      Object.assign(config.headers, headers);
    }
    config.params = Object.assign({}, config.params);
    if (!!userDetails.restaurant || !!config.rest_id) {
        config.params['rest_id'] = config.rest_id || userDetails.restaurant.id;
    }
    // if (shouldAskOrdersInRequest()) {
    //     config.params['with_active_orders'] = true;
    // }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
      if (!!response.data.active_orders && Object.keys(response.data.active_orders).length > 0) {
          localStorage.setItem('activeOrders', JSON.stringify(response.data.active_orders));
          store.dispatch(updateActiveOrdersAction(response.data.active_orders));
      } else {
          localStorage.removeItem('activeOrders');
      }
      return response;
  }, (error) => {return Promise.reject(error);}
);

export default axiosInstance;
