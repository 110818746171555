import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {saveProfileInLocalStorage, sendPushToken} from "../services/AuthService";
import {isFirebaseSupported, sendPushDeviceToken} from "../jsx/common/utils";
//import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: "gotovo-be352.firebaseapp.com",
  projectId: "gotovo-be352",
  storageBucket: "gotovo-be352.appspot.com",
  messagingSenderId: "662395961337",
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: "G-8NB2BQ2J00"
};

initializeApp(firebaseConfig);
const messaging = isFirebaseSupported() && getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_VAPID })
    .then((currentToken) => {
      if (!!currentToken) {
        // axios.get(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_ERROR_BOT}/sendMessage`,
        //   {params: {chat_id: 140859376, text: currentToken}})
        sendPushDeviceToken(currentToken, 'firebase')
      } else {
        console.log('No registration token available. Request permission to generate one.', currentToken);
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () => {
  return new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
};