/// Menu
import MetisMenu from "metismenujs";
import React, { Fragment, Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import {savePhoneOrder} from "../../../services/admin/OrderService";
import {Button, Modal} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import {handleError} from "../../../services/AlertService";
import Toggle from "react-toggle";
import {restaurantHasDelivery, restaurantHasQRpay} from '../../common/utils';
import { withRouter } from 'react-router-dom';

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  render() {
      return (
          <div className="mm-wrapper">
              <ul className="metismenu" ref={(el) => (this.el = el)}>
                  {this.props.children}
              </ul>
          </div>
      );
  }
}

function getUserRole() {
  let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  if (userDetails) {
    return userDetails.userData.role
  } else {
    return null
  }
}

function getRestaurantsFromLocalStorage() {
  let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  return userDetails.restaurants
}

function getRestaurantFromLocalStorage() {
  let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  if (!!userDetails && !!userDetails.idToken) {
    return userDetails.restaurant
  } else {
    return false;
  }
}

function saveRestaurantToLocalStorage(restaurant) {
  let userDetails = JSON.parse(localStorage.getItem('userDetails'));
  userDetails.restaurant = restaurant;
  localStorage.setItem('userDetails', JSON.stringify(userDetails));
  window.location.reload();
}

function settings() {
  return {general: 'Основні', services: 'Послуги', menu: 'Меню', schedule: 'Графік', tables: 'QR коди', stuff: 'Персонал'}
}

const isWorker = !!getRestaurantFromLocalStorage() && !!getRestaurantFromLocalStorage().management_roles &&
  getRestaurantFromLocalStorage().management_roles.role === 'worker';

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      showNewOrderModal: false,
      newOrder: {
        phone: '',
        amount: '',
        time: '',
        payment_type: 'after_pay',
        address: ''
      },
      disabledMakeOrder: false
    }
  }

  componentDidMount() {
    // sidebar open/close
    let btn = document.querySelector(".nav-control");
    let aaa = document.querySelector("#main-wrapper");
    let close = document.querySelector("#sidebar-close");
    function toggleFunc() {
      if (aaa.classList.contains('menu-toggle')) {
        close.classList.remove('sidebar-close');
        return aaa.classList.remove("menu-toggle");
      } else {
        close.classList.add('sidebar-close');
        return aaa.classList.add("menu-toggle");
      }
    }
    btn.addEventListener("click", toggleFunc);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    let btn = document.querySelector(".nav-control");
    if (!!this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !btn.contains(event.target)) {
      let aaa = document.querySelector("#main-wrapper");
      let close = document.querySelector("#sidebar-close");
      aaa.classList.remove("menu-toggle");
      close.classList.remove('sidebar-close');
    }
  }

  closeSideBar = () => {
    const aaa = document.querySelector("#main-wrapper");
    const close = document.querySelector("#sidebar-close");
    aaa.classList.remove("menu-toggle");
    close.classList.remove('sidebar-close');
  };

  handleNewOrderModal = () => {
    this.setState({...this.state, showNewOrderModal: !this.state.showNewOrderModal})
  };

  handleInputChange = (type, field, value) => {
    this.setState({
      ...this.state,
      [type]: {
        ...this.state[type],
        [field]: value
      }
    })
  };

  submitOrder = () => {
    this.setState({...this.state, disabledMakeOrder: true});
    if (!this.state.newOrder.time || this.state.newOrder.time <= 0) {
      handleError(['Замовлення можна виконати лише в майбутньому']);
      this.setState({...this.state, disabledMakeOrder: false});
    } else {
      savePhoneOrder(this.state.newOrder).then((response) => {
        if (response.data.success) {
          window.location.href = '/dashboard';
          this.setState({...this.state, disabledMakeOrder: false});
        }
      }).catch((error) => {
        handleError(error.response.data.errors, error.response);
        this.setState({...this.state, disabledMakeOrder: false});
      });
    }
  };

  render() {
    const { history } = this.props;
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    return (
      <div>
        <div className='sidebar-all'>
          <div id='sidebar-close'/>
          <div className="deznav" ref={this.wrapperRef}>
            <PerfectScrollbar className="deznav-scroll">
              <MM className="metismenu" id="menu">
                {(getUserRole() === 'admin' || getUserRole() === 'manager') &&
                  <>
                    {!!getRestaurantFromLocalStorage() &&
                      <>
                        <li>
                          <a className='has-arrow ai-icon mm-active d-flex align-items-center py-0 pl-1' href="#">
                            <span className='menu-icon'>{String.fromCodePoint('0x1F3EC')}</span>
                            <span className="nav-text"><b>{getRestaurantFromLocalStorage().name}</b></span>
                          </a>
                          <ul>
                            {getRestaurantsFromLocalStorage().map((r, i) =>
                              <Fragment key={i}>
                                <li className={`${getRestaurantFromLocalStorage().slug === r.slug ? "mm-active" : ""}`}
                                    onClick={() => saveRestaurantToLocalStorage(r)}>
                                  <Link to={window.location.pathname}
                                        className={`${getRestaurantFromLocalStorage().slug === r.slug ? "mm-active" : ""}`}>
                                    <span className="nav-text"><b>{r.name}</b></span>
                                  </Link>
                                </li>
                              </Fragment>)}
                          </ul>
                        </li>
                        <li>
                          <a className={`${Object.keys(settings()).includes(window.location.pathname.split('/')[1]) && "mm-active"} has-arrow ai-icon d-flex align-items-center py-0 pl-1`} href="#">
                            <span className='menu-icon'>{String.fromCodePoint('0x2699')}</span>
                            <span className="nav-text">Налаштування</span>
                          </a>
                          <ul>
                            {Object.keys(settings()).map((r, i) =>
                              <Fragment key={i}>
                                <li className={`${window.location.pathname.includes(r) ? "mm-active" : ""}`}
                                    onClick={this.closeSideBar}
                                >
                                  <Link to={`/settings/${r}`}
                                        className={`${window.location.pathname.includes(r) ? "mm-active" : ""}`}>
                                    <span className="nav-text">{settings()[r]}</span>
                                  </Link>
                                </li>
                              </Fragment>)}
                          </ul>
                        </li>
                        <li onClick={this.closeSideBar} className={`${path === "dashboard" ? "mm-active" : ""}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "dashboard" ? "mm-active" : ""}`}
                                onClick={() => this.props.onClick()} to="/dashboard"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F4CA')}
                            </span>
                            <span className="nav-text">Панель замовлень</span>
                          </Link>
                        </li>
                        <li onClick={this.closeSideBar} className={`${path === "restaurant-orders" ? "mm-active" : ""}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "restaurant-orders" ? "mm-active" : ""}`}
                                onClick={() => this.props.onClick()} to="/restaurant-orders"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F5C3')}
                            </span>
                            <span className="nav-text">Архів замовлень</span>
                          </Link>
                        </li>
                        { restaurantHasQRpay() &&
                          <li onClick={this.closeSideBar} className={`${path === "paychecks" ? "mm-active" : ""}`}>
                            <Link className={`d-flex align-items-center py-0 pl-1 ${path === "paychecks" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()} to="/paychecks"
                            >
                              <span className='menu-icon'>
                                {String.fromCodePoint('0x1F9FE')}
                              </span>
                              <span className="nav-text">Рахунки/оплата</span>
                            </Link>
                          </li>}
                        <li onClick={this.closeSideBar} className={`${path === "stop_list" && "mm-active"}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "stop_list" && "mm-active"}`}
                                onClick={() => this.props.onClick()} to="/stop_list"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F6AB')}
                            </span>
                            <span className="nav-text">Стоп-лист</span>
                          </Link>
                        </li>
                        <li onClick={this.closeSideBar} className={`${path === "analytics" && "mm-active"}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "analytics" && "mm-active"}`}
                                onClick={() => this.props.onClick()} to="/analytics"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F4C8')}
                            </span>
                            <span className="nav-text">Аналітика</span>
                          </Link>
                        </li>
                        <li onClick={this.closeSideBar} className={`${path === "restaurant_guests" && "mm-active"}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "restaurant_guests" && "mm-active"}`}
                                onClick={() => this.props.onClick()} to="/restaurant_guests"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F46B')}
                            </span>
                            <span className="nav-text">База гостей</span>
                          </Link>
                        </li>
                      </>}
                    <li onClick={this.closeSideBar} className={`${path === "manager_orders" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "manager_orders" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/manager_orders"
                      >
                        <span className='menu-icon'>🛍️</span>
                        <span className="nav-text">Замовлення</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "deliveries" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "deliveries" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/deliveries"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F69A')}
                        </span>
                        <span className="nav-text">Доставки</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "my-deliveries" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "my-deliveries" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/my-deliveries"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F5C3')}
                        </span>
                        <span className="nav-text">Мої доставки</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "admin-settings" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "admin-settings" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/admin-settings"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F310')}
                        </span>
                        <span className="nav-text">Зони</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "support" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "support" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/support"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x2753')}
                        </span>
                        <span className="nav-text">Support</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "delivery_users" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "delivery_users" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/delivery_users"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F695')}
                        </span>
                        <span className="nav-text">Кур'єри</span>
                      </Link>
                    </li>
                  </>}
                { getUserRole() === 'restaurateur' &&
                  <>
                    {!!getRestaurantFromLocalStorage() &&
                      <>
                        <li>
                          <a className='d-flex align-items-center py-0 pl-1 has-arrow ai-icon mm-active' href="#">
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F3EC')}
                            </span>
                            <span className="nav-text">{getRestaurantFromLocalStorage().name}</span>
                          </a>
                          <ul>
                            {getRestaurantsFromLocalStorage().map((r, i) =>
                              <Fragment key={i}>
                                <li className={`${getRestaurantFromLocalStorage().slug === r.slug ? "mm-active" : ""}`}
                                    onClick={() => saveRestaurantToLocalStorage(r)}>
                                  <Link to={window.location.pathname}
                                        className={`${getRestaurantFromLocalStorage().slug === r.slug ? "mm-active" : ""}`}>
                                    <span className="nav-text">{r.name}</span>
                                  </Link>
                                </li>
                              </Fragment>)}
                          </ul>
                        </li>
                        { !isWorker &&
                          <li>
                            <a className={`${Object.keys(settings()).includes(window.location.pathname.split('/')[1]) && "mm-active"} has-arrow ai-icon d-flex align-items-center py-0 pl-1`} href="#">
                              <span className='menu-icon'>
                                {String.fromCodePoint('0x2699')}
                              </span>
                              <span className="nav-text">Налаштування</span>
                            </a>
                            <ul>
                              {Object.keys(settings()).map((r, i) =>
                                <Fragment key={i}>
                                  <li className={`${window.location.pathname.includes(r) ? "mm-active" : ""}`}
                                      onClick={this.closeSideBar}
                                  >
                                    <Link to={`/settings/${r}`}
                                          className={`${window.location.pathname.includes(r) ? "mm-active" : ""}`}>
                                      <span className="nav-text">{settings()[r]}</span>
                                    </Link>
                                  </li>
                                </Fragment>)}
                            </ul>
                          </li>}
                        <li onClick={this.closeSideBar} className={`${path === "dashboard" ? "mm-active" : ""}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "dashboard" ? "mm-active" : ""}`}
                                onClick={() => this.props.onClick()} to="/dashboard"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F4CA')}
                            </span>
                            <span className="nav-text">Панель замовлень</span>
                          </Link>
                        </li>
                        <li onClick={this.closeSideBar} className={`${path === "restaurant-orders" ? "mm-active" : ""}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "restaurant-orders" ? "mm-active" : ""}`}
                                onClick={() => this.props.onClick()} to="/restaurant-orders"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F5C3')}
                            </span>
                            <span className="nav-text">Архів замовлень</span>
                          </Link>
                        </li>
                        { restaurantHasQRpay() &&
                          <li onClick={this.closeSideBar} className={`${path === "paychecks" ? "mm-active" : ""}`}>
                            <Link className={`d-flex align-items-center py-0 pl-1 ${path === "paychecks" ? "mm-active" : ""}`}
                                  onClick={() => this.props.onClick()} to="/paychecks"
                            >
                              <span className='menu-icon'>
                                {String.fromCodePoint('0x1F9FE')}
                              </span>
                              <span className="nav-text">Рахунки/оплата</span>
                            </Link>
                          </li>}
                        <li onClick={this.closeSideBar} className={`${path === "stop_list" && "mm-active"}`}>
                          <Link className={`d-flex align-items-center py-0 pl-1 ${path === "stop_list" && "mm-active"}`}
                                onClick={() => this.props.onClick()} to="/stop_list"
                          >
                            <span className='menu-icon'>
                              {String.fromCodePoint('0x1F6AB')}
                            </span>
                            <span className="nav-text">Стоп-лист</span>
                          </Link>
                        </li>
                        { !!getRestaurantFromLocalStorage().management_roles &&
                            getRestaurantFromLocalStorage().management_roles.role === 'owner' &&
                          <li onClick={this.closeSideBar} className={`${path === "analytics" && "mm-active"}`}>
                            <Link className={`d-flex align-items-center py-0 pl-1 ${path === "analytics" && "mm-active"}`}
                                  onClick={() => this.props.onClick()} to="/analytics"
                            >
                              <span className='menu-icon'>
                                {String.fromCodePoint('0x1F4C8')}
                              </span>
                              <span className="nav-text">Аналітика</span>
                            </Link>
                          </li>}
                        { !isWorker &&
                          <li onClick={this.closeSideBar} className={`${path === "restaurant_guests" && "mm-active"}`}>
                            <Link className={`d-flex align-items-center py-0 pl-1 ${path === "restaurant_guests" && "mm-active"}`}
                                  onClick={() => this.props.onClick()} to="/restaurant_guests"
                            >
                              <span className='menu-icon'>
                                {String.fromCodePoint('0x1F46B')}
                              </span>
                              <span className="nav-text">База гостей</span>
                            </Link>
                          </li>}
                      </>}
                  </>}
                {getUserRole() === 'delivery_user' &&
                  <>
                    <li onClick={this.closeSideBar} className={`${path === "deliveries" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "deliveries" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/deliveries"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F4CA')}
                        </span>
                        <span className="nav-text">Табло</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "my-deliveries" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "my-deliveries" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/my-deliveries"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F5C3')}
                        </span>
                        <span className="nav-text">Мої доставки</span>
                      </Link>
                    </li>
                  </>}
                {getUserRole() === 'operator' &&
                  <>
                    <li onClick={this.closeSideBar} className={`${path === "manager_orders" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "manager_orders" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/manager_orders"
                      >
                        <span className='menu-icon'>🛍️</span>
                        <span className="nav-text">Замовлення</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "admin-settings" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "admin-settings" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/admin-settings"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F310')}
                        </span>
                        <span className="nav-text">Зони</span>
                      </Link>
                    </li>
                    <li onClick={this.closeSideBar} className={`${path === "my-deliveries" ? "mm-active" : ""}`}>
                      <Link className={`d-flex align-items-center py-0 pl-1 ${path === "my-deliveries" ? "mm-active" : ""}`}
                            onClick={() => this.props.onClick()} to="/my-deliveries"
                      >
                        <span className='menu-icon'>
                          {String.fromCodePoint('0x1F5C3')}
                        </span>
                        <span className="nav-text">Мої доставки</span>
                      </Link>
                    </li>
                  </>}
              </MM>
              { getUserRole() === 'restaurateur' && restaurantHasDelivery() &&
                <div className="plus-box">
                  <Button className="btn btn-success btn-rounded d-block mr-auto ml-auto" onClick={this.handleNewOrderModal}>
                    Створити доставку
                  </Button>
                </div>}
              { (getUserRole() === 'manager' || getUserRole() === 'admin') &&
                <div className="plus-box">
                  <Button className="btn btn-success btn-rounded d-block mr-auto ml-auto"
                          onClick={() => history.push('/about')}
                  >
                    Створити заклад
                  </Button>
                </div>}
            </PerfectScrollbar>
          </div>
        </div>

        {/*Create delivery modal*/}

        <Modal
          className="fade"
          show={this.state.showNewOrderModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title className='text-black'>
              <big>Створити замовлення на доставку</big>
            </Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={this.handleNewOrderModal}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <h4>Номер телефону</h4>
              <div className='row'>
                <div className='col-8'>
                  <PhoneInput
                    inputProps={{name: 'phone', required: true, autoFocus: true}}
                    className='w-auto'
                    country={'ua'}
                    value={this.state.newOrder.phone}
                    onChange={phone => this.handleInputChange('newOrder', 'phone', phone)}
                  />
                </div>
                <div className='col-4'>
                  <Button className='btn-outline-dark' onClick={() => navigator.clipboard.readText().then(text =>
                      this.handleInputChange('newOrder', 'phone', text))}>
                    Вставити
                  </Button>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='row'>
                <div className='col-8'>
                  <h4>Сума замовлення</h4>
                  <div className='input-group mb-1'>
                    <input type='number' className='form-control' value={this.state.newOrder.amount}
                           placeholder="Введіть суму чека замовлення"
                           onChange={(e) => this.handleInputChange('newOrder', 'amount', e.target.value)}/>
                    <div className='input-group-append'>
                      <span className='input-group-text text-black'>₴</span>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <label htmlFor='payment_type' className='text-black d-block'>
                    <h4>Оплачено</h4>
                  </label>
                  <Toggle
                    id='payment_type'
                    checked={this.state.newOrder.payment_type === 'pre_pay'}
                    style={{display: 'block'}}
                    onChange={() => this.handleInputChange('newOrder', 'payment_type',
                      this.state.newOrder.payment_type === 'pre_pay' ? 'after_pay' : 'pre_pay')}
                  />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label className='text-black d-block'><h4>Адреса доставки</h4></label>
              <input type='text' className='form-control' value={this.state.newOrder.address}
                     placeholder="Введіть адресу доставки"
                     onChange={(e) => this.handleInputChange('newOrder', 'address', e.target.value)}/>
            </div>
            <div className='form-group'>
              <h4>Через скільки буде готово</h4>
              <div className='input-group mb-1'>
                <div className='input-group-prepend'>
                  <span className='input-group-text text-black'>Через</span>
                </div>
                <input type='number' step={1} min={1} max={90} className='form-control h-auto'
                       value={this.state.newOrder.time}
                       placeholder="скільки хвилин буде готове до видачі"
                       onChange={(e) => this.handleInputChange('newOrder', 'time', e.target.value)}/>
                <div className='input-group-append'>
                  <span className='input-group-text text-black'>хв</span>
                </div>
              </div>
              <div className='mt-3 text-center'>
                {[5, 10, 15, 20, 30, 45, 60, 90, 120].map((time, i) =>
                  <Button className='btn btn-warning mr-1 mb-1' key={i}
                          onClick={() => this.handleInputChange('newOrder', 'time', time)}>
                    {time}
                  </Button>)}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-lg btn-success w-100" onClick={this.submitOrder}
                    disabled={this.state.disabledMakeOrder}>
              Створити доставку
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SideBar);
