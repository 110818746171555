import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {ReactComponent as Left} from "../../../../src/icons/left.svg";
import {ReactComponent as Refresh} from "../../../../src/icons/refresh.svg";
import {ReactComponent as Moon} from "../../../../src/icons/moon-theme.svg";
import {ReactComponent as Sun} from "../../../../src/icons/sun-theme.svg";
import {useDispatch, useSelector} from "react-redux";
import {switchThemeAction, updateProfileCompleteAction} from '../../../store/actions/AuthActions';
import {saveProfileInLocalStorage, submitProfile} from "../../../services/AuthService";

const BottomNav = () => {
  const history = useHistory();
  const store = useSelector(store => store.auth);
  const dispatch = useDispatch();

  function switchTheme() {
    let dataAttr = document.querySelector("body");
    const newTheme = store.auth.userData.theme === 'dark' ? 'light' : 'dark';
    dataAttr.setAttribute('data-theme-version', newTheme);
    if (!!store.auth.idToken) {
      submitProfile({theme: newTheme}).then((response) => {
        saveProfileInLocalStorage(response.data.profile);
        dispatch(updateProfileCompleteAction(response.data.profile));
      })
    } else {
      let old = localStorage.getItem('userDetails');
      if (old === null) {
        old = {};
      } else {
        old = JSON.parse(old);
      }
      const newDetails = {
        ...old,
        userData: {
          ...old.userData,
          theme: newTheme,
        },
      };
      localStorage.setItem('userDetails', JSON.stringify(newDetails));
      dispatch(switchThemeAction(newTheme))
    }
  }

  return (
    <div className='bottom-nav'>
      <Left onClick={() => history.goBack()}/>
      <Refresh onClick={() => window.location.reload()}/>
      { !!store.auth.userData && store.auth.userData.theme === 'dark' ?
        <Sun onClick={switchTheme} style={{width: 30, height: 30}}/>
      :
        <Moon onClick={switchTheme} style={{width: 30, height: 30}}/>}
    </div>
  );
};

export default BottomNav;
