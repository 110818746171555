export const GET_RESTAURANT = '[Restaurant Action] Get Restaurant';
export const GET_RESTAURANTS = '[Restaurant Action] Get Restaurants';
export const CREATE_REVIEW = '[Create Review Action] Create Review';
export const REVIEW_FAILED_ACTION = '[Create Review Action] Failed Review';
export const GET_RESTAURANTS_WITH_ROUTES = '[GET_RESTAURANTS_WITH_ROUTES Action] GET_RESTAURANTS_WITH_ROUTES';
export const GET_DISHES = '[Get Dishes Action] Get Dishes';
export const SET_POINT = '[Set Point Action] Set Point';
export const GET_REVIEWS = '[Get Reviews Action] Get Reviews';
export const REQUEST_WAITER = '[REQUEST_WAITER Action] REQUEST_WAITER';
export const DIVIDE_PAYCHECK = '[DIVIDE_PAYCHECK Action] DIVIDE_PAYCHECK';
export const GET_PAYCHECK = '[GET_PAYCHECK Action] GET_PAYCHECK';
export const GET_IMAGES = '[GET_IMAGES Action] GET_IMAGES';
export const GET_CATEGORY_DISHES = '[GET_CATEGORY_DISHES Action] GET_CATEGORY_DISHES';
export const RATE_DISH = '[RATE_DISH Action] RATE_DISH';
export const SELECT_RESTAURANT = '[SELECT_RESTAURANT Action] SELECT_RESTAURANT';
export const GET_RESTAURANTS_CATEGORIES = '[GET_RESTAURANTS_CATEGORIES Action] GET_RESTAURANTS_CATEGORIES';
export const SELECT_CATEGORY = '[SELECT_CATEGORY Action] SELECT_CATEGORY';
export const SORT_RESTAURANTS = '[SORT_RESTAURANTS Action] SORT_RESTAURANTS';
export const GET_ROUTE_CATEGORIES = '[GET_ROUTE_CATEGORIES Action] GET_ROUTE_CATEGORIES';
export const SET_MAP_VIEW = '[SET_MAP_VIEW Action] SET_MAP_VIEW';
export const GET_COMPANIES = '[GET_COMPANIES Action] GET_COMPANIES';
export const GET_COMPANY = '[GET_COMPANY Action] GET_COMPANY';
export const GET_CATEGORY = '[GET_CATEGORY Action] GET_CATEGORY';