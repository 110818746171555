import {
  CREATE_REVIEW,
  GET_RESTAURANT,
  GET_RESTAURANTS,
  GET_RESTAURANTS_WITH_ROUTES,
  GET_DISHES,
  SET_POINT,
  GET_REVIEWS,
  REQUEST_WAITER,
  DIVIDE_PAYCHECK,
  GET_PAYCHECK,
  GET_IMAGES,
  GET_CATEGORY_DISHES,
  RATE_DISH,
  SELECT_RESTAURANT,
  GET_RESTAURANTS_CATEGORIES,
  SELECT_CATEGORY,
  SORT_RESTAURANTS,
  GET_ROUTE_CATEGORIES,
  SET_MAP_VIEW,
  GET_COMPANIES,
  GET_COMPANY, GET_CATEGORY
} from '../actions/RestaurantTypes';

const initialState = {
  restaurants: {},
  restaurantsLoaded: false,
  routes: [],
  startPoint: {
    location: [],
    name: ''
  },
  endPoint: {
    location: [],
    name: ''
  },
  selectedRestaurant: 0,
  location: [],
  categories: {},
  selectedCategory: false,
  selectedSort: 'popular',
  allMapScanned: false,
  bounds: false,
  mapCenter: false,
  mapZoom: 0,
  companies: {}
};

export default function RestaurantsReducer(state = initialState, actions) {
  if (actions.type === SET_POINT) {
    return {
      ...state,
      [actions.payload.kind]: actions.payload.location
    };
  }

  if (actions.type === GET_RESTAURANTS_WITH_ROUTES) {
    return {
      ...state,
      restaurants: actions.payload,
      restaurantsLoaded: true,
      routes: actions.routes
    };
  }

  if (actions.type === GET_RESTAURANTS) {
    return {
      ...state,
      restaurants: Object.assign(actions.payload, state.restaurants),
      restaurantsLoaded: true,
      location: actions.location,
      bounds: actions.bounds,
      allMapScanned: actions.allMapScanned
    };
  }

  if (actions.type === GET_COMPANIES) {
    return {
      ...state,
      companies: Object.assign(actions.payload, state.companies)
    };
  }

  if (actions.type === GET_COMPANY) {
    let company = {};
    if (!!state.companies[actions.payload.slug]) {
      company = Object.assign(state.companies[actions.payload.slug], actions.payload);
    } else {
      company = actions.payload
    }
    return {
      ...state,
      companies: {
        ...state.companies,
        [actions.payload.slug]: company
      }
    };
  }

  if (actions.type === GET_RESTAURANT) {
    let restaurant = {};
    if (!!state.restaurants[actions.payload.slug]) {
      restaurant = Object.assign(state.restaurants[actions.payload.slug], actions.payload);
    } else {
      restaurant = actions.payload
    }
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.payload.slug]: restaurant
      }
    };
  }

  if (actions.type === SELECT_RESTAURANT) {
    return {
      ...state,
      selectedRestaurant: actions.payload.id
    };
  }

  if (actions.type === GET_REVIEWS) {
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: {
          ...state.restaurants[actions.restaurant.slug],
          reviews: actions.payload
        }
      }
    };
  }

  if (actions.type === CREATE_REVIEW) {
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: Object.assign(actions.restaurant, {reviews: actions.payload})
      }
    };
  }

  if (actions.type === GET_DISHES) {
    if (!!state.restaurants[actions.restaurant.slug]) {
      return {
        ...state,
        restaurants: {
          ...state.restaurants,
          [actions.restaurant.slug]: {
            ...state.restaurants[actions.restaurant.slug],
            menu: actions.payload
          }
        }
      };
    } else {
      const restaurant = Object.assign(actions.restaurant, {menu: actions.payload});
      return {
        ...state,
        restaurants: {
          ...state.restaurants,
          [actions.restaurant.slug]: restaurant
        }
      };
    }
  }

  if (actions.type === GET_CATEGORY) {
    return {
      ...state,
      companies: {
        ...state.companies,
        [actions.company.slug]: {
          ...state.companies[actions.company.slug],
          categories: {
            ...state.companies[actions.company.slug].categories,
            [actions.categoryType]: {
              ...state.companies[actions.company.slug].categories[actions.categoryType],
              restaurants: {
                ...state.companies[actions.company.slug].categories[actions.categoryType].restaurants,
                [actions.restaurant.id]: {
                  ...state.companies[actions.company.slug].categories[actions.categoryType].restaurants[actions.restaurant.id],
                  ...actions.restaurant,
                  menu: {
                    ...state.companies[actions.company.slug].categories[actions.categoryType].restaurants[actions.restaurant.id].menu,
                    [` ${actions.payload.id}`]: actions.payload
                  }
                }
              }
            }
          }
        }
      }
    };
    // return {
    //   ...state,
    //   companies: {
    //     ...state.companies,
    //     [actions.company.slug]: {
    //       ...state.companies[actions.company.slug],
    //       restaurants: {
    //         ...state.companies[actions.company.slug].restaurants,
    //         [actions.restaurant.slug]: {
    //           ...state.companies[actions.company.slug].restaurants[actions.restaurant.slug],
    //           menu: {
    //             ...(!!state.companies[actions.company.slug].restaurants[actions.restaurant.slug].menu
    //               ? state.companies[actions.company.slug].restaurants[actions.restaurant.slug].menu
    //               : {}),
    //             [` ${actions.payload.id}`]: actions.payload
    //           }
    //         }
    //       }
    //     }
    //   }
    // };
    // return {
    //   ...state,
    //   categories: {
    //     ...state.categories,
    //     [actions.categoryType]: {
    //       ...state.categories[actions.categoryType],
    //       restaurants: {
    //         ...state.categories[actions.categoryType].restaurants,
    //         [actions.restaurant.id]: {
    //           ...state.categories[actions.categoryType].restaurants[actions.restaurant.id],
    //           category_dishes: {
    //             ...state.categories[actions.categoryType].restaurants[actions.restaurant.id].category_dishes,
    //             [` ${actions.payload.id}`]: actions.payload
    //           }
    //         }
    //       }
    //     }
    //   }
    // };
  }

  if (actions.type === REQUEST_WAITER) {
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: {
          ...state.restaurants[actions.restaurant.slug],
          table: actions.payload
        }
      }
    };
  }

  if (actions.type === DIVIDE_PAYCHECK) {
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: {
          ...state.restaurants[actions.restaurant.slug],
          table: {
            ...state.restaurants[actions.restaurant.slug].table,
            pay_check: actions.payload
          }
        }
      }
    };
  }

  if (actions.type === GET_PAYCHECK) {
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: {
          ...state.restaurants[actions.restaurant.slug],
          table: actions.payload
        }
      }
    };
  }

  if (actions.type === GET_IMAGES) {
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: {
          ...state.restaurants[actions.restaurant.slug],
          photos: actions.payload
        }
      }
    };
  }

  if (actions.type === GET_CATEGORY_DISHES) {
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: {
          ...state.restaurants[actions.restaurant.slug],
          menu: {
            ...state.restaurants[actions.restaurant.slug].menu,
            [` ${actions.category.id}`]: {
              ...state.restaurants[actions.restaurant.slug].menu[` ${actions.category.id}`],
              photos_loaded: actions.category.photos_loaded,
              subItems: actions.payload
            }
          }
        }
      }
    };
  }

  if (actions.type === RATE_DISH) {
    const previousDishes = state.restaurants[actions.restaurant.slug].menu[` ${actions.category.id}`].subItems;
    const subItems = [...state.restaurants[actions.restaurant.slug].menu[` ${actions.category.id}`].subItems
      .filter((item) => item.sub_category_id === actions.payload.sub_category_id)];
    subItems[actions.dishIndex] = Object.assign(subItems[actions.dishIndex],
      {likes: actions.likes, dislikes: actions.dislikes});
    subItems.push(previousDishes.filter((item) => item.sub_category_id !== actions.payload.sub_category_id));
    return {
      ...state,
      restaurants: {
        ...state.restaurants,
        [actions.restaurant.slug]: {
          ...state.restaurants[actions.restaurant.slug],
          menu: {
            ...state.restaurants[actions.restaurant.slug].menu,
            [` ${actions.category.id}`]: {
              ...state.restaurants[actions.restaurant.slug].menu[` ${actions.category.id}`],
              subItems: [].concat.apply([], subItems)
            }
          }
        }
      }
    };
  }

  if (actions.type === GET_RESTAURANTS_CATEGORIES) {
    const oldCategories = state.categories;
    const newCategories = actions.payload;
    const categories = {};

    Object.keys(oldCategories).forEach((categoryKey) => {
      const oldCategory = oldCategories[categoryKey];
      const newCategory = Object.assign({}, oldCategory);
      if (newCategories.hasOwnProperty(categoryKey)) {
        const newCategoryRestaurants = newCategories[categoryKey].restaurants || {};
        const oldCategoryRestaurants = oldCategory.restaurants || {};
        newCategory.restaurants = Object.assign({}, oldCategoryRestaurants, newCategoryRestaurants);
      }
      categories[categoryKey] = newCategory;
    });

    Object.keys(newCategories).forEach((categoryKey) => {
      if (!categories.hasOwnProperty(categoryKey)) {
        categories[categoryKey] = newCategories[categoryKey];
      }
    });

    return {
      ...state,
      categories: categories
    };
  }

  if (actions.type === GET_ROUTE_CATEGORIES) {
    return {
      ...state,
      categories: actions.payload
    };
  }

  if (actions.type === SELECT_CATEGORY) {
    return {
      ...state,
      selectedCategory: actions.payload
    };
  }

  if (actions.type === SORT_RESTAURANTS) {
    return {
      ...state,
      selectedSort: actions.payload
    };
  }

  if (actions.type === SET_MAP_VIEW) {
    return {
      ...state,
      mapCenter: actions.payload,
      mapZoom: actions.zoom,
    };
  }

  return state;
}
