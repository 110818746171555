import React, {Suspense, useState} from 'react';
/// Components
import Index from './jsx/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// action
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./css/style.css";
import Loading from "./jsx/common/Loading";
import { ErrorBoundary } from "react-error-boundary";
import Error500 from "./jsx/pages/Error500";
//require('dotenv').config();
import axios from 'axios';
import {sendPushDeviceToken} from "./jsx/common/utils";

window.receiveDeviceToken = (token, apiKey) => {
  if (process.env.REACT_APP_GOTOVO_API_KEY === apiKey) {
    sendPushDeviceToken(token, 'apple')
  }
};

window.receiveAndroidDeviceToken = (token, apiKey) => {
  if (process.env.REACT_APP_GOTOVO_API_KEY === apiKey) {
    sendPushDeviceToken(token, 'android').then(() =>
      window.ReactNativeWebView.postMessage(JSON.stringify('Token saved'))
    ).catch(() =>
      window.ReactNativeWebView.postMessage(JSON.stringify('Token error'))
    )
  } else {
    window.ReactNativeWebView.postMessage(JSON.stringify('Wrong API key'));
  }
};

window.restaurantConfig = (apiKey) => {
  if (process.env.REACT_APP_GOTOVO_API_KEY === apiKey) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    if (tokenDetails.userData.role === 'restaurateur') {
      window.ReactNativeWebView.postMessage(JSON.stringify(tokenDetails));
    }
  }
};

function App (props) {
  const [error, setError] = useState(null);

  const logError = (error, info) => {
    setError({ error, info });
    if (window.location.host === 'test.gotovo.app' || window.location.host === 'gotovo.app') {
      if (!(/Loading chunk [\d]+ failed/.test(error.message))) {
        axios.get(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_ERROR_BOT}/sendMessage`,
        {
          params: {
            chat_id: 140859376,
            text: `${window.location.pathname} \n ${error.message} \n ${info.componentStack} \n\n ${error.stack}`
          }
        })
      }
    }
  };

  return (
    <ErrorBoundary FallbackComponent={() => <Error500 error={error} />} onError={logError}>
      <Suspense fallback={<Loading whole={true}/>}>
        <Index isAuthenticated={props.isAuthenticated} history={props.history}/>
      </Suspense>
    </ErrorBoundary>);
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App)); 
