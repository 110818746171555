import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
/// Scroll
import LogoutPage from './Logout';
/// Image
import missingAvatar from '../../../../src/images/user/missing.jpg';
import {ReactComponent as Qr} from '../../../icons/qr.svg';
import {ReactComponent as Play} from '../../../icons/google-play.svg';
import {ReactComponent as AppStore} from '../../../icons/apple.svg';
import {ReactComponent as User} from '../../../icons/user.svg';
import {ReactComponent as Orders} from '../../../icons/list.svg';
import {ReactComponent as Telegram} from '../../../icons/Telegram.svg';
import {ReactComponent as Plus} from "../../../icons/plus.svg";
import {getOrderDishesAction} from "../../../store/actions/OrdersActions";
import {Button, Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import QRcodeReader from '../../common/QRcodeReader';
import BusketOrders from "../../components/Public/Orders/BusketOrders";
import {isIOSWebView} from "../../common/utils";

const Header = ({ onNote, toggle, onProfile, onActivity, onNotification, setToggle}) => {
   const dispatch = useDispatch();
  const [state, setState] = useState({showQR: false});
  let path = window.location.pathname.split("/");
   let name = path[path.length - 1].split("-");
   let filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   let finalName = filterName.includes("app")
      ? filterName.filter((f) => f !== "app")
      : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
      ? filterName.filter((f) => f !== "uc")
      : filterName.includes("basic")
      ? filterName.filter((f) => f !== "basic")
      : filterName.includes("form")
      ? filterName.filter((f) => f !== "form")
      : filterName.includes("table")
      ? filterName.filter((f) => f !== "table")
      : filterName.includes("page")
      ? filterName.filter((f) => f !== "page")
      : filterName.includes("email")
      ? filterName.filter((f) => f !== "email")
      : filterName.includes("ecom")
      ? filterName.filter((f) => f !== "ecom")
      : filterName.includes("chart")
      ? filterName.filter((f) => f !== "chart")
      : filterName.includes("editor")
      ? filterName.filter((f) => f !== "editor")
      : filterName;
  let page_name = (finalName.join(" ") === '')?'':finalName.join(" ");
  const storeAuth = useSelector(store => store.auth);
  const userData = storeAuth.auth.userData;
  const storeOrders = useSelector(store => store.orders);
  const orderDishes = storeOrders.orderDishes;
  const dishes = Object.values(orderDishes).map((o) => Object.keys(o.dishes).length)
    .reduce((partialSum, a) => partialSum + a, 0);
  const profileRef = useRef(null);
  const [isOpenedByPWA, setIsOpenedByPWA] = useState(false);
  const { t } = useTranslation();
  const logos = [
    <Play fill='white' width={20} height={20} style={{marginLeft: 3}}/>,
    <AppStore fill='white' width={20} height={20} style={{marginBottom: 3}}/>
  ];

  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLogoIndex((prevIndex) => prevIndex === 0 ? 1 : 0);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const currentLogo = logos[currentLogoIndex];

  useEffect(() => {
    const checkPWA = () => {
      if (
        window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone ||
        (window.webkit && window.webkit.messageHandlers.standalone) || isIOSWebView()
      ) {
        setIsOpenedByPWA(true);
      }
    };

    checkPWA();
    window.addEventListener('DOMContentLoaded', checkPWA);

    return () => {
      window.removeEventListener('DOMContentLoaded', checkPWA);
    };
  }, []);

  useEffect(() => {
    let installDetails = JSON.parse(localStorage.getItem('Gotovo'));
    if (!!installDetails) {
      installDetails.optedOut = false;
      localStorage.setItem('Gotovo', JSON.stringify(installDetails));
    }
    dispatch(getOrderDishesAction());
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      let btn = document.querySelector(".header-profile");
      if (profileRef.current && !profileRef.current.contains(event.target) && !btn.contains(event.target)) {
        setToggle('')
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  function handleInstallApp() {
    window.location.href = '/install';
    //window.location.reload();
  }

  function isInstalled() {
    let installDetails = JSON.parse(localStorage.getItem('Gotovo'));
    if (!!installDetails) {
      return installDetails.added;
    } else {
      return false;
    }
  }

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
    localStorage.setItem('language', e);
  };

  function activeOrders() {
    return Object.values(storeOrders.activeOrders).filter((o) => o.status === 'created' ||
      o.status === 'payed' || o.status === 'cooking' || o.status === 'ready' || o.status === 'on_delivery')
  }

   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div className="dashboard_bar text-black" style={{ textTransform: "capitalize" }}>
                        {page_name}
                     </div>
                  </div>

                  <ul className="navbar-nav header-right">
                    <li className="nav-item dropdown notification_dropdown">
                      <span className="btn-primary nav-icons" id='toogleLanguage'
                            onClick={() => handleLanguageChange(i18n.language === 'ua' ? 'en' : 'ua')}
                      >
                        {i18n.language === 'ua' ? 'UA' : 'EN'}
                      </span>
                    </li>
                    <li className="nav-item dropdown notification_dropdown">
                      <span className="btn-primary nav-icons" id='toogleQRscanner'
                         onClick={() => setState({...state, showQR: !state.showQR})}
                      >
                        <Qr/>
                      </span>
                    </li>
                    { !(isInstalled() || isOpenedByPWA) &&
                      <li className="nav-item dropdown notification_dropdown">
                        <a href={"/install"} className="btn-primary nav-icons position-relative" onClick={handleInstallApp}>
                          {currentLogo}
                        </a>
                      </li>}
                    { dishes > 0 &&
                       <li className="nav-item dropdown notification_dropdown"><BusketOrders/></li>}
                    { !!userData &&
                       <li onClick={() => onProfile()}
                           className={`nav-item dropdown header-profile ${toggle === "profile" ? "show" : ""}`}
                       >
                          <a className="nav-link" role="button" data-toggle="dropdown">
                             <div className="header-info">
                                 <small>{userData.email}</small>
                                 <span>{userData.first_name} {userData.last_name}</span>
                             </div>
                             <div className='profile-photo mb-0'
                                  style={{verticalAlign: 'middle',
                                    backgroundImage: `url(${!!userData.picture ? userData.picture : missingAvatar})`}}>
                               { activeOrders().length > 0 &&
                                 <span className='selected-dishes fs-16'>{activeOrders().length}</span>}
                             </div>
                             {/*<img src={!!userData.picture ? userData.picture : missingAvatar} width="20" alt="" />*/}
                          </a>
                          <div
                             ref={profileRef}
                             className={`dropdown-menu dropdown-menu-right profile pt-4 ${
                                toggle === "profile" ? "show" : ""
                             }`}
                          >
                            <Button variant="" className="close" onClick={() => setToggle('')}
                                    style={{fontSize: '50px', position: 'absolute', top: '-5px', right: '5px',
                                      color: !!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}}
                            >
                              <span>&times;</span>
                            </Button>
                            { !!storeAuth.auth.idToken ?
                              <>
                                <Link to="/profile" className="dropdown-item ai-icon">
                                  <User stroke={!!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}/>
                                  <span className="text-black" style={{marginLeft: '10px'}}>{t('profile')}</span>
                                </Link>
                                <Link to="/orders" className="dropdown-item ai-icon" style={{position: 'relative'}}>
                                  { activeOrders().length > 0 &&
                                    <span className='selected-dishes fs-16' style={{right: 8}}>
                                      {activeOrders().length}
                                    </span>}
                                  <Orders fill={!!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}/>
                                  <span className="ml-2 text-black">{t('myOrders')}</span>
                                </Link>
                                <hr/>
                                <a href="https://t.me/gotovoApp_bot" className="dropdown-item ai-icon">
                                  <Telegram fill={!!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}/>
                                  <span className="ml-2 text-black">{t('support')}</span>
                                </a>
                                <hr/>
                                <Link to="/about" className="dropdown-item ai-icon">
                                  <Plus fill={!!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}/>
                                  <span className="text-black" style={{marginLeft: '10px'}}>{t('addRestaurant')}</span>
                                </Link>
                                <hr/>
                                <LogoutPage />
                              </>
                            :
                              <>
                                <a href="https://t.me/gotovoApp_bot" className="dropdown-item ai-icon">
                                  <Telegram fill={!!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}/>
                                  <span className="ml-2 text-black">{t('support')}</span>
                                </a>
                                <hr/>
                                <Link to="/about" className="dropdown-item ai-icon">
                                  <Plus fill={!!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}/>
                                  <span className="text-black" style={{marginLeft: '10px'}}>{t('addRestaurant')}</span>
                                </Link>
                                <hr/>
                                <Link to="/login" className="dropdown-item ai-icon">
                                  <User stroke={!!userData && userData.theme === 'dark' ? 'white' : '#2F2D2A'}/>
                                  <span className="ml-2 text-black">{t('login')}</span>
                                </Link>
                              </>}
                          </div>
                       </li>}
					 
                  </ul>
               </div>
            </nav>
         </div>

        <Modal className="fade" show={state.showQR}>
          <Modal.Header style={{justifyContent: 'center'}}>
            <Modal.Title><h4 className='text-black mb-0 text-center'>{t('components.header.scanQR')}</h4></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QRcodeReader/>
            <Button className='btn btn-lg btn-primary w-100 mt-3'
                    onClick={() => setState({...state, showQR: !state.showQR})}
            >
              {t('cancel')}
            </Button>
          </Modal.Body>
        </Modal>

        {/*<Modal className="fade" show={state.showLanguageModal}>*/}
        {/*  <Modal.Body>*/}
        {/*    <Button className={`w-100 mt-3 d-flex align-items-center justify-content-center ${i18n.language === 'en' ? 'btn-primary' : 'btn-secondary'}`}*/}
        {/*            onClick={() => handleLanguageChange('en')}*/}
        {/*    >*/}
        {/*      <span className='fs-32 mr-2'>{String.fromCodePoint('0x1F1EC', '0x1F1E7')}</span>*/}
        {/*      <span className='fs-24'>English</span>*/}
        {/*    </Button>*/}
        {/*    <Button className={`w-100 mt-3 d-flex align-items-center justify-content-center ${i18n.language === 'ua' ? 'btn-primary' : 'btn-secondary'}`}*/}
        {/*            onClick={() => handleLanguageChange('ua')}*/}
        {/*    >*/}
        {/*      <span className='fs-32 mr-2'>{String.fromCodePoint('0x1F1FA', '0x1F1E6')}</span>*/}
        {/*      <span className='fs-24'>Українська</span>*/}
        {/*    </Button>*/}
        {/*  </Modal.Body>*/}
        {/*</Modal>*/}
      </div>
   );
};

export default Header;
