import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/logo.png";
import logoText from "../../../images/logo-text.png";
import {useSelector} from "react-redux";

const NavHader = () => {
  const storeAuth = useSelector(store => store.auth);
  const userData = storeAuth.auth.userData;

  return (
      <div className="nav-header" style={{background: 'none'}}>
         <Link to={"/"} className="brand-logo"
           style={{marginLeft: (!!userData && (!storeAuth.auth.idToken || userData.role === 'default')) ? 0 : 20}}
         >
            <img className="logo-abbr" src={logo} alt="" />
            <img className="logo-compact" src={logoText} alt="" />
            <img className="brand-title" src={logoText} alt="" />
         </Link>

         <div className={`nav-control
          ${(!!userData && (!storeAuth.auth.idToken || userData.role === 'default')) && 'd-none'}`}>
            <div className='hamburger'>
               <span className="line"/>
               <span className="line"/>
               <span className="line"/>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
