import React, {useState, useEffect, Fragment, } from "react";
import {Button, Modal} from "react-bootstrap";
import {
  handleDishOrderCountAction,
  handleSelectDishVariationAction,
  removeOrderDishesAction,
} from "../../../../store/actions/OrdersActions";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactComponent as Cart} from "../../../../icons/cart.svg";
import {ReactComponent as Trash} from "../../../../icons/trash.svg";
import {translateRecord, orderSummary, dishesAmount} from "../../../common/utils";
import {PhotoProvider, PhotoView} from "react-photo-view";

const BusketOrders = (props) => {
  const storeOrders = useSelector(store => store.orders);
  const storeAuth = useSelector(store => store.auth);
  const storeRestaurant = useSelector(store => store.restaurants);
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation();
  const orderDishes = storeOrders.orderDishes;
  const [state, setState] = useState({
    busketOrdersModal: false,
    selectedRestaurant: false,
    selectedCompany: false,
  });

  const dishesCount = () => {
    if (!!props.slug) {
      return Object.values(orderDishes).filter((r) => r.restaurant.slug === props.slug)
        .map((o) => Object.keys(o.dishes).length).reduce((partialSum, a) => partialSum + a, 0)
    } else {
      return Object.values(orderDishes).map((o) => Object.keys(o.dishes).length)
        .reduce((partialSum, a) => partialSum + a, 0)
    }
  };

  function removeDishes(slug) {
    dispatch(removeOrderDishesAction(slug));
  }

  function dishParameter(dish, param, slug) {
    if (param === 'name') {
      return translateRecord(dish, param);
    } else if (param === 'weight') {
      return !!dish.weight ? `${dish.weight}${t(`measures.${dish['measure']}`)}` : '';
    } else {
      return dish[param];
    }
  }

  function tableId(slug) {
    if (!!storeRestaurant.restaurants[slug]) {
      if (!!storeRestaurant.restaurants[slug].table) {
        return storeRestaurant.restaurants[slug].table.id
      } else if (![].concat.apply([], Object.keys(orderDishes[slug].restaurant.order_types)
        .filter(o => o !== 'on_table').map((order_type) =>
          Object.values(orderDishes[slug].restaurant.order_types[order_type])))
        .includes(true) && !!orderDishes[slug].restaurant.table) {
        return orderDishes[slug].restaurant.table.id;
      }
    } else {
      return '';
    }
  }

  function measureUnit(dish) {
    return dish.weight_flag && !dish.selectedVariation ? t(`measures.${dish['measure']}`) : '';
  }

  function handleDishCount(s, dish, value) {
    const rSlug = s;
    const newQuantity = storeOrders.orderDishes[rSlug].dishes[dish.uid].quantity + value;
    let reducerDishes = {};
    if (newQuantity > (!!dish.min_order ? parseFloat(dish.min_order) - (dish.weight_flag ? parseFloat(dish.weight) : 1) : 0)) {
      reducerDishes = {
        ...storeOrders.orderDishes,
        [rSlug]: {
          ...storeOrders.orderDishes[rSlug],
          dishes: {
            ...storeOrders.orderDishes[rSlug].dishes,
            [dish.uid]: {
              ...storeOrders.orderDishes[rSlug].dishes[dish.uid],
              quantity: parseFloat(newQuantity)
            }
          }
        }
      };
      dispatch(handleDishOrderCountAction(reducerDishes, storeOrders.orderDishes[rSlug].restaurant));
    } else {
      let dishes = storeOrders.orderDishes[rSlug].dishes;
      delete dishes[dish.uid];
      if (Object.keys(dishes).length > 0) {
        reducerDishes = {
          ...storeOrders.orderDishes,
          [rSlug]: {
            ...storeOrders.orderDishes[rSlug],
            dishes: dishes
          }
        };
        dispatch(handleDishOrderCountAction(reducerDishes, storeOrders.orderDishes[rSlug].restaurant));
      } else {
        dispatch(removeOrderDishesAction(rSlug));
        setState({...state, selectedRestaurant: Object.keys(orderDishes).filter((s) => s !== rSlug)[0]})
      }
    }
  }

  function calculateCount(dish) {
    if (dish.weight_flag && !dish.selectedVariation) {
      return parseFloat(dish.weight);
    } else {
      return 1;
    }
  }

  const companies = () => {
    const companiesMap = {};

    for (const key in orderDishes) {
      const { company } = orderDishes[key];
      if (company && !companiesMap[company.slug]) {
        companiesMap[company.slug] = company;
      }
    }

    return companiesMap;
  };

  const calculateTotalPrice = (dish) => {
    let result = Object.values(dish.selectedMods).reduce((acc, mod) => {
      return acc + (Number(mod.price) * mod.quantity);
    }, 0);
    return result * parseFloat(dish.quantity);
  };

  const restaurantBusket = (slug) => <>
    { Object.values(storeOrders.orderDishes[slug].dishes).map((dish, index) =>
      <Fragment key={index}>
        <div className="order-dish d-flex justify-content-between align-items-center">
          <div className='d-flex align-items-center'>
            <Button className='order-count mr-2'
              onClick={() => handleDishCount(slug, dish, -calculateCount(dish))}
              style={{
                color: !!storeAuth.auth.userData && storeAuth.auth.userData.theme === 'dark' ? 'white' : '#130B01',
                padding: '0 7.5px'
              }}
            >
              <strong>-</strong>
            </Button>
            <div>
              <h6 className='mb-0'>
                { !!dish.categoryPrice ?
                  translateRecord(dish, 'category_name')
                  :
                  dishParameter(dish, 'name', slug)}
              </h6>
              <p className='mb-0 text-black'>
                <small>{dishParameter(dish, 'weight')} {!!dish.selectedVariation && `${!!dishParameter(dish, 'weight') ? '-' : ''} ${translateRecord(dish.selectedVariation, 'name')}`}</small>
              </p>
              { !dish.categoryPrice &&
                <div className='d-flex align-items-center'>
                  <span className="text-primary mb-0 mr-1">
                    {dishParameter(dish, 'price', slug)}
                    <small>₴</small>
                  </span>
                  { dish.quantity > 1 &&
                    <Button className='btn-primary' style={{padding: '0 3px', borderRadius: '3px'}}>
                      x<strong>{dish.quantity}<small>{measureUnit(dish)}</small></strong>
                    </Button>}
                </div>}
              { !!dish.selectedMods &&
                <p className='text-muted mb-1' style={{lineHeight: 1}}>
                  { Object.values(dish.selectedMods).map((m,i) =>
                    <span key={i} className='mr-1' style={{fontSize: 11}}>
                      {dish.group_modifications[m.group_modification_id][m.id].name}{m.quantity > 1 && <span className='text-primary'> x<b>{m.quantity}</b></span>}{Object.keys(dish.selectedMods).length - 1 !== i && ','}
                    </span>)}
                  <span className='text-primary'> +<b>{calculateTotalPrice(dish)}</b><small>₴</small></span>
                </p>}
            </div>
          </div>
          <div className='d-flex align-items-center'>
            { !!dish.photo && !props.fromDashboard &&
              <PhotoProvider speed={() => 200}
                             easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
              >
                <PhotoView src={dish.photo}>
                  <div className="profile-photo m-0 ml-1"
                       style={{backgroundImage: !!dish.photo && `url(${dish.photo})`,
                         height: 45, width: 45, minWidth: 45}}
                  />
                </PhotoView>
              </PhotoProvider>}
            { !dish.categoryPrice &&
              <Button className='ml-2 order-count'
                      onClick={() => handleDishCount(slug, dish, calculateCount(dish))}
                      style={{color: !!storeAuth.auth.userData && storeAuth.auth.userData.theme === 'dark' ? 'white' : '#130B01'}}
              >
                <strong>+</strong>
              </Button>}
          </div>
        </div>
      </Fragment>)}
    { !!orderDishes[slug].company ?
      <Link className='btn btn-lg btn-primary w-100 mt-2' to={`/check-out/${orderDishes[slug].company.slug}`}
            onClick={() => setState({...state, busketOrdersModal: false})}
      >
        {t('components.menu.toOrder')} <span>{companyDishesAmount()}<small>₴</small></span>
      </Link>
    :
      <>
        { !(!!storeRestaurant.restaurants[slug] && storeRestaurant.restaurants[slug].table) &&
             !orderDishes[slug].restaurant.isSplited && !props.fromDashboard &&
          <Link className='btn btn-lg btn-primary w-100 mt-2' to={`/checkout/${slug}`}
                onClick={() => setState({...state, busketOrdersModal: false})}
          >
            {t('components.menu.toOrder')} <span>{dishesAmount(slug)}<small>₴</small></span>
          </Link>}
      </>}
  </>;

  function openModal() {
    if (!!companies()) {
      setState({...state, selectedCompany: Object.keys(companies())[0], busketOrdersModal: true,
        selectedRestaurant: !!Object.keys(companies())[0] && Object.values(orderDishes)
          .filter((o) => !!o.company && o.company.slug === Object.keys(companies())[0])[0].restaurant.slug})
    } else {
      setState({...state, busketOrdersModal: true})
    }
  }

  function companyDishesAmount() {
    let result = 0;
    Object.values(orderDishes).filter((o) => !!o.company && o.company.slug === state.selectedCompany).forEach((o) =>
      result += dishesAmount(o.restaurant.slug)
    );
    return result;
  }

  function removeCompany() {
    Object.values(orderDishes).filter((o) => !!o.company).forEach((r) =>
      removeDishes(r.restaurant.slug)
    );
    setState({...state, selectedCompany: false, selectedRestaurant: false})
  }

  return (
    <>
      { dishesCount() > 0 &&
        <>
          <div style={{position: 'relative'}} className="btn-primary nav-icons" onClick={openModal}>
            <Cart/>
            <span className="selected-dishes" style={{
              background: !!storeAuth.auth.userData && storeAuth.auth.userData.theme === 'dark' ? '#F5F5F5' : '#2F2D2A',
              color: !!storeAuth.auth.userData && storeAuth.auth.userData.theme === 'dark' ? '#262626' : '#F5F5F5'}}
            >
              {dishesCount()}
            </span>
          </div>

          {!!orderDishes &&
          <Modal className="fade" show={state.busketOrdersModal}>
            <Modal.Header>
              <Modal.Title className='text-black'>{t('cart')}</Modal.Title>
              <Button variant="" className="close" onClick={() => setState({...state, busketOrdersModal: false})}>
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body
              style={{background: !!storeAuth.auth.userData && storeAuth.auth.userData.theme === 'dark' ? '#161615' : '#f2f2f2'}}
            >
              { Object.keys(companies()).length > 0 &&
                <div className='card position-relative' style={{boxShadow: '0px 2px 12px rgba(29, 10, 3, 0.2)'}}>
                  <Button className='btn-danger position-absolute fs-20' onClick={removeCompany}
                          style={{left: -15, top: -15, padding: '0 7px 4px', borderRadius: 17}}
                  >
                    <Trash height={20} width={20} fill='white'/>
                  </Button>
                  <div className='card-body'>
                    { Object.keys(companies()).length > 0 &&
                      <div className='d-flex align-items-center justify-content-around order-types mb-3'
                         style={{overflowX: 'scroll', whiteSpace: 'nowrap', width: '100%'}}
                      >
                        { Object.values(companies()).map((c, i) =>
                          <div onClick={() => setState({...state, selectedCompany: c.slug,
                            selectedRestaurant: Object.values(orderDishes)
                            .filter((o) => !!o.company && o.company.slug === c.slug)[0].restaurant.slug})}
                               key={i}
                               style={{height: '100%', borderRadius: 8, alignItems: 'center', display: 'flex'}}
                               className={`${state.selectedCompany === c.slug && 'btn-primary'} px-2`}
                          >
                            <h6 className='mb-0' style={{color: state.selectedCompany === c.slug && 'white'}}>
                              {translateRecord(c, 'name')}
                            </h6>
                          </div>)}
                      </div>}
                    <div className={`d-flex align-items-center order-types mb-3 ${Object.keys(orderDishes).length < 3 && 'justify-content-around'}`}
                         style={{overflowX: 'scroll', whiteSpace: 'nowrap', width: '100%'}}
                    >
                      { Object.values(orderDishes).filter((o) => !!o.company && o.company.slug === state.selectedCompany).map((r, i) =>
                        <div onClick={() => setState({...state, selectedRestaurant: r.restaurant.slug})} key={i}
                             style={{height: '100%', borderRadius: 8, alignItems: 'center', display: 'flex'}}
                             className={`${state.selectedRestaurant === r.restaurant.slug && 'btn-primary'} px-2`}
                        >
                          <h6 className='mb-0' style={{color: state.selectedRestaurant === r.restaurant.slug && 'white'}}>
                            {translateRecord(r.restaurant, 'name')}
                          </h6>
                        </div>)}
                    </div>
                    { !!state.selectedRestaurant &&
                      <>
                        {restaurantBusket(state.selectedRestaurant)}
                      </>}
                  </div>
                </div>}
              { Object.values(orderDishes).filter((o) => !o.company).map((o,i) => {
                const slug = o.restaurant.slug;
                if (!!props.slug ? props.slug === slug : true) {
                  return (
                    <div key={i} className='mb-4 card' style={{boxShadow: '0px 2px 12px rgba(29, 10, 3, 0.2)'}}>
                      { Object.keys(orderDishes[slug]).length > 0 &&
                      <div className='card-body'>
                        { !props.fromDashboard &&
                        <div className='position-relative'>
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className='mb-0'>
                              <Link to={`/r/${slug}`}>
                                {translateRecord(orderDishes[slug].restaurant, 'name')}
                              </Link>
                            </h3>
                            { !orderDishes[slug].restaurant.isSplited &&
                            <Link className='btn btn-xxs btn-outline-primary' to={`/r/${slug}/menu?${tableId(slug)}`}
                                  onClick={() => setState({...state, busketOrdersModal: false})}
                            >
                              {t('toMenu')}
                            </Link>}
                            <Button className='btn-danger position-absolute fs-20'
                                    style={{left: -30, top: -30, padding: '0 7px 4px', borderRadius: 17}}
                                    onClick={() => removeDishes(slug)}
                            >
                              <Trash height={20} width={20} fill='white'/>
                            </Button>
                          </div>
                        </div>}
                        { restaurantBusket(slug) }
                      </div>}
                    </div>)}})}
            </Modal.Body>
          </Modal>}
        </>}
    </>
  );
};

export default BusketOrders;