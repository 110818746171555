import React, {Fragment} from "react";
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {removeOrderDishesAction} from "../../../store/actions/OrdersActions";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import {translateRecord} from "../../common/utils";

const Alerts = ({ toggleTab, PerfectScrollbar, toggleChatBox }) => {
   const dispatch = useDispatch();
   const store = useSelector(store => store.orders);
   const storeRestaurant = useSelector(store => store.restaurants);
   const { t } = useTranslation();

   function removeDishes(slug) {
      dispatch(removeOrderDishesAction(slug));
   }

   function dishParameter(dish, param, slug) {
      if (!!dish.dish_restaurant_id && param === 'name') {
         return translateRecord(store.orderDishes[slug].dishes[dish.dish_restaurant_id], 'name');
      } else if (param === 'name') {
         return translateRecord(dish, param);
      } else if (param === 'weight') {
         return !!dish.weight ? `${dish.weight}${t(`measures.${dish['measure']}`)}` : '';
      } else {
         return dish[param];
      }
   }

   function orderSummary(slug) {
      let summary = [];
      let checkedCategories = [];
      Object.values(store.orderDishes[slug].dishes).map((dish) => {
         if (!!dish.selectedVariations && Object.keys(dish.selectedVariations).length > 0) {
            Object.values(dish.selectedVariations).map((v) => {
               if (!checkedCategories.includes(dish.category_dish_id) && !!dish.categoryPrice) {
                  checkedCategories.push(dish.category_dish_id);
                  summary.push(parseFloat(dish.categoryPrice))
               } else if (!dish.categoryPrice) {
                  summary.push(parseFloat(v.price) * parseFloat(v.quantity))
               }
            })
         } else {
            summary.push(calculatePrice(dish))
         }
      });
      return summary.reduce((partialSum, a) => partialSum + a, 0);
   }

   function calculatePrice(dish) {
      if (dish.weight_flag) {
         return (parseFloat(dishParameter(dish, 'price')) / parseFloat(dish.weight)) * parseFloat(dish.quantity)
      } else {
         return parseFloat(dishParameter(dish, 'price')) * parseFloat(dish.quantity);
      }
   }

   function dishes(slug) {
      let result = [];
      [].concat.apply([], Object.values(store.orderDishes[slug].dishes)).map((dish) => {
         if (Object.keys(!!dish.selectedVariations && dish.selectedVariations).length > 0) {
            Object.values(dish.selectedVariations).map((v) =>
              result.push(Object.assign(v, {photo: dish.photo, category_dish_id: dish.category_dish_id})))
         } else {
            result.push(dish)
         }
      });
      return result;
   }

   function tableId(slug) {
      if (!!storeRestaurant.restaurants[slug]) {
         if (!!storeRestaurant.restaurants[slug].table) {
            return storeRestaurant.restaurants[slug].table.id
         } else if (![].concat.apply([], Object.keys(store.orderDishes[slug].restaurant.order_types)
           .filter(o => o !== 'on_table').map((order_type) =>
             Object.values(store.orderDishes[slug].restaurant.order_types[order_type])))
           .includes(true) && !!store.orderDishes[slug].restaurant.table) {
            return store.orderDishes[slug].restaurant.table.id;
         }
      } else {
         return '';
      }
   }

   function measureUnit(dish) {
      return dish.weight_flag && !dish.dish_restaurant_id ? t(`measures.${dish['measure']}`) : '';
   }

   // console.log('storeRestaurant', storeRestaurant)
   // console.log('storeAlerts', store)

   return (
      <div className={`tab-pane fade  ${toggleTab === "alerts" ? "active show" : ""}`} id="alerts" role="tabpanel">
         <div className="card mb-sm-3 mb-md-0 contacts_card">
            <PerfectScrollbar
               className={`card-body contacts_body p-0 dz-scroll ${toggleChatBox ? "ps ps--active-y" : ""}`}
               id="DZ_W_Contacts_Body1"
            >
               <ul className="contacts">
                  { Object.keys(store.orderDishes).map((slug,i) => {
                     return (
                       <Fragment key={i}>
                       { Object.keys(store.orderDishes[slug]).length > 0 &&
                         <Fragment>
                            <li className="name-first-letter d-flex justify-content-between">
                               <big><Link to={`/r/${slug}`}>
                                  {translateRecord(store.orderDishes[slug].restaurant, 'name')}
                               </Link></big>
                               <Button className='btn btn-xxs btn-danger'
                                       onClick={() => removeDishes(slug)}>{t('destroy')}</Button>
                            </li>
                            { !store.orderDishes[slug].restaurant.isSplited &&
                               <li className="order-summary">
                                  <Link className='btn btn-xxs btn-primary' to={`/r/${slug}/menu?${tableId(slug)}`}>
                                     {t('toMenu')}
                                  </Link>
                               </li>}
                            { dishes(slug).map((dish, index) =>
                              <Fragment key={index}>
                                 <li className="active">
                                    <div className="d-flex bd-highlight justify-content-between">
                                       <div className="user_info">
                                          <span>
                                             { !!dish.categoryPrice ?
                                               translateRecord(dish, 'category_name')
                                             :
                                               dishParameter(dish, 'name', slug)}
                                          </span>
                                          <span className='mb-2'>
                                             <small>{dishParameter(dish, 'weight')} {!!dish.dish_restaurant_id && `${!!dishParameter(dish, 'weight') ? '-' : ''} ${translateRecord(dish, 'name')}`}</small>
                                          </span>
                                          { !dish.categoryPrice &&
                                             <div className='d-flex align-items-center'>
                                                <span className="text-primary mb-0 mr-1">
                                                   {dishParameter(dish, 'price', slug)}<small>₴</small>
                                                </span>
                                                { dish.quantity > 1 &&
                                                   <Button className='btn-primary'
                                                     style={{padding: '0 3px', borderRadius: '3px'}}
                                                   >
                                                      x<strong>{dish.quantity}<small>{measureUnit(dish)}</small></strong>
                                                   </Button>}
                                             </div>}
                                       </div>
                                       { !!dish.photo &&
                                          <div className="profile-photo m-0 ml-1"
                                               style={{backgroundImage: !!dish.photo && `url(${dish.photo})`,
                                                  height: 45, width: 45, minWidth: 45 }}
                                          />}
                                    </div>
                                 </li>
                              </Fragment>)}
                            {!(!!storeRestaurant.restaurants[slug] && storeRestaurant.restaurants[slug].table) &&
                                !store.orderDishes[slug].restaurant.isSplited &&
                               <li className="order-summary" key={i}>
                                  <Link className='btn-xs btn-success fs-16 w-100 text-center' to={`/checkout/${slug}`}
                                        style={{borderRadius: '10px'}}>
                                     {t('components.menu.toOrder')} <span>{orderSummary(slug)}<small>₴</small></span>
                                  </Link>
                               </li>}
                         </Fragment>}
                       </Fragment>)})}

               </ul>
            </PerfectScrollbar>
            <div className="card-footer"/>
         </div>
      </div>
   );
};

export default Alerts;
