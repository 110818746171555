import {LOADING_TOGGLE_ACTION, LOGIN_CONFIRMED_ACTION, LOGIN_FAILED_ACTION, LOGOUT_ACTION, SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION, UPDATE_PROFILE_ACTION, DELETE_PROFILE_ACTION, SWITCH_THEME, USER_LOCATION
} from '../actions/AuthActions';

const initialState = {
    auth: Object.assign({
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
        userData: {
            location: {lat: null, lng: null}
        }
    }, JSON.parse(localStorage.getItem('userDetails'))),
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    mapKey: 0
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
                userData: {}
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    if (action.type === UPDATE_PROFILE_ACTION) {
        return {
            ...state,
            auth: {
                ...state.auth,
                userData: action.payload
            }
        };
    }

    if (action.type === DELETE_PROFILE_ACTION) {
        return initialState;
    }

    if (action.type === SWITCH_THEME) {
        return {
            ...state,
            auth: {
                ...state.auth,
                userData: {
                    ...state.auth.userData,
                    theme: action.payload
                }
            },
            mapKey: state.mapKey + 1
        };
    }

    if (action.type === USER_LOCATION) {
        if (!state.auth.userData.location || (action.payload.lat !== state.auth.userData.location.lat && action.payload.lng !== state.auth.userData.location.lng)) {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    userData: {
                        ...state.auth.userData,
                        location: action.payload
                    }
                }
            };
        }
    }

    return state;
}

    
