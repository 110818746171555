import {
  getOrders,
  makeOrder,
  removeDishesFromLocalStorage,
  getOrder,
  checkInvoiceStatus,
  payPayCheck,
  checkPayShareInvoiceStatus,
  saveOrderDishesToLocalStorage,
  updateOrder,
  refundOrder,
  updateOrderMessage,
  cancelOrder,
  makeBulkOrder,
  removeCategoryDishesFromLocalStorage,
  getGroupOrders, cancelBulkOrder
} from "../../services/OrderService";
import {confirmedGetPayChecksAction} from "./RestaurantActions";
import {handleAlert, handleError} from "../../services/AlertService";
import {MAKE_ORDER, GET_ORDERS, GET_ORDER, DISH_COUNT, ADD_DISH, GET_CHECKOUT_DISHES,
  REMOVE_ORDER_DISHES, WAITER_REQEST, SELECT_DISH_VARIATION, ADD_CUTLERY, CUTLERY_COUNT, RATE_DISH_FROM_ORDER,
  REMOVE_DISH_FROM_ORDER, CHANGE_ORDER_BULK_CHECKOUT, CHANGE_ORDER_CHECKOUT, ADD_DISH_TO_PAYSHARE, APPROVE_ORDER,
  SET_ORDER_MESSAGE, UPDATE_ORDER_MESSAGES, UPDATE_ACTIVE_ORDERS, SET_ACTIVE_ORDER, CHANGE_DISH_ORDER,
  REMOVE_ORDER_CATEGORY_DISHES, GET_GROUP_ORDERS
} from "./OrderTypes";
import {saveActiveOrderInLocalStorage, saveTokenInLocalStorage} from "../../services/AuthService";
import {GET_DISHES} from "./RestaurantTypes";
import {saveRateDish} from "../../services/RestaurantService";
import {createOrderMessage, makeOperatorOrder} from "../../services/admin/OrderService";

export function getOrdersAction(page) {
  return (dispatch) => {
    getOrders(page).then((response) => {
      dispatch(confirmedGetOrdersAction(response.data.orders, response.data.total_pages, page));
    })
  };
}

export function getOrderAction(id, search) {
  return (dispatch) => {
    return getOrder(id, !!search && search.split('=')[1]).then((response) => {
      dispatch(confirmedGetOrderAction(response.data.order));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response);
    });
  };
}

export function getGroupOrdersAction(id) {
  return (dispatch) => {
    return getGroupOrders(id).then((response) => {
      dispatch(confirmedGetGroupOrdersAction(response.data.orders));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response);
    });
  };
}

export function makeOrderAction(restaurant, orders, params, payMethod, history) {
  return (dispatch) => {
    return makeOrder(restaurant.id, orders, params, payMethod).then((response) => {
      const order = response.data.order;
      dispatch(setActiveOrderAction(order));
      removeDishesFromLocalStorage(restaurant.slug);
      if (!!response.data.user) {
        saveTokenInLocalStorage(Object.assign(response.headers, response));
      }
      history.push(`/orders/${response.data.order.id}`);
      if (!!order.invoice_url && !order.shared) {
        window.location.href = order.invoice_url;
      } else {
        dispatch(confirmedMakeOrderAction(order));
        if (!!response.data.user) {
          window.location.href = `/orders/${response.data.order.id}`;
        }
      }
    })
  };
}

export function makeOperatorOrderAction(restaurant, orders, params, history) {
  return (dispatch) => {
    return makeOperatorOrder(restaurant.id, orders, params).then((resp) => {
      removeDishesFromLocalStorage(restaurant.slug);
      dispatch(confirmedMakeOrderAction(resp.data.order));
      history.push(`/manager_orders`);
    })
  };
}

export function makeBulkOrderAction(dishes, params, history) {
  return (dispatch) => {
    return makeBulkOrder(dishes, params).then((response) => {
      const orders = response.data.orders;
      //dispatch(setActiveOrderAction(orders[0]));
      orders.map((o) => {
        dispatch(setActiveOrderAction(o));
        removeDishesFromLocalStorage(o.restaurant.slug);
      });
      if (!!response.data.user) {
        saveTokenInLocalStorage(Object.assign(response.headers, response));
      }
      history.push(`/orders/${response.data.orders[0].id}`);
      if (!!orders[0].invoice_url) {
        window.location.href = orders[0].invoice_url;
      } else {
        orders.map((o) => dispatch(confirmedMakeOrderAction(o)));
        if (!!response.data.user) {
          window.location.href = `/orders/${response.data.orders[0].id}`;
        }
      }
    })
  };
}

export function payCheckAction(restaurant, payCheck, history) {
  return (dispatch) => {
    return payPayCheck(restaurant, payCheck).then((response) => {
      if (!!response.data.user) {
        saveTokenInLocalStorage(Object.assign(response.headers, response));
      }
      if (!!response.data.invoice_url) {
        window.location.href = response.data.invoice_url
      } else {
        dispatch(confirmedGetPayChecksAction(response.data.table, restaurant));
      }
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function checkInvoiceStatusAction(id) {
  return (dispatch) => {
    return checkInvoiceStatus(id).then((response) => {
      if (response.data.success) {
        dispatch(confirmedGetOrderAction(response.data.order));
      } else {
        window.location.href = response.data.order.invoice_url
      }
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function checkPayShareInvoiceStatusAction(order, payShare) {
  return (dispatch) => {
    return checkPayShareInvoiceStatus(order, payShare).then((response) => {
      if (!!response.data.user) {
        saveTokenInLocalStorage(Object.assign(response.headers, response));
      }
      if (response.data.success) {
        dispatch(confirmedGetOrderAction(response.data.order));
      } else {
        window.location.href = response.data.order.pay_shares[payShare.id].invoice_url
      }
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function rateDishFromOrderAction(dish, kind) {
  return (dispatch) => {
    return saveRateDish(dish, kind).then((response) => {
      dispatch(confirmedRateDishAction(dish.dish_order_id, response.data.likes, response.data.dislikes));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function updateOrderAction(params, order) {
  return (dispatch) => {
    updateOrder(params, order).then((response) => {
      if (response.data.success) {
        dispatch(confirmedGetOrderAction(response.data.order));
      } else {
        handleAlert('success', 'Gotovo', response.data.message);
      }
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function refundOrderAction(order, history) {
  return (dispatch) => {
    refundOrder(order).then((response) => {
      dispatch(confirmedGetOrderAction(response.data.order));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function cancelOrderAction(id) {
  return (dispatch) => {
    cancelOrder(id).then((response) => {
      dispatch(confirmedGetOrderAction(response.data.order));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function cancelBulkOrderAction(ids) {
  return (dispatch) => {
    cancelBulkOrder(ids).then((response) => {
      dispatch(confirmedGetOrderAction(response.data.order));
    }).catch((error) => {
      handleError(error.response.data.errors, error.response)
    });
  };
}

export function handleAddDishToPayShareAction(payShares) {
  return {
    type: ADD_DISH_TO_PAYSHARE,
    payload: payShares
  };
}

export function confirmedRateDishAction(dishOrderId, likes, dislikes) {
  return {
    type: RATE_DISH_FROM_ORDER,
    payload: dishOrderId,
    likes: likes,
    dislikes: dislikes
  };
}

export function handleSelectDishVariationAction(restaurant, reducerDishes) {
  saveOrderDishesToLocalStorage({restaurant: restaurant, dishes: reducerDishes[restaurant.slug].dishes, company: reducerDishes[restaurant.slug].company});
  return {
    type: SELECT_DISH_VARIATION,
    payload: reducerDishes,
    restaurant: restaurant
  };
}

export function getMenuFromLocalStorageAction() {
  const local = localStorage.getItem('checkoutDishes');
  const checkoutDishes = JSON.parse(local);
  if (checkoutDishes && Object.keys(checkoutDishes).length > 0) {
    return {
      type: GET_DISHES,
      payload: checkoutDishes.restaurant.menu
    };
  } else {
    return {type: ''};
  }
}

export function getOrderDishesAction() {
  const local = localStorage.getItem('checkoutDishes');
  const checkoutDishes = JSON.parse(local);
  if (checkoutDishes && Object.keys(checkoutDishes).length > 0) {
    return {
      type: GET_CHECKOUT_DISHES,
      payload: checkoutDishes
    };
  } else {
    return {type: ''};
  }
}

export function removeDishFromOrderAction(restaurant, newDishes) {
  saveOrderDishesToLocalStorage(newDishes);
  return {
    type: REMOVE_DISH_FROM_ORDER,
    payload: newDishes,
    restaurant: restaurant
  };
}

export function handleAddDishToOrderAction(restaurant, newDishes) {
  saveOrderDishesToLocalStorage(newDishes);
  return {
    type: ADD_DISH,
    payload: newDishes,
    restaurant: restaurant
  };
}

export function handleAddCutleryToOrderAction(restaurant, cutlery) {
  return {
    type: ADD_CUTLERY,
    payload: Object.assign(cutlery, {quantity: 1}),
    restaurant: restaurant
  };
}

export function handleCountCutleryToOrderAction(restaurant, cutlery_id, value) {
  return {
    type: CUTLERY_COUNT,
    payload: cutlery_id,
    restaurant: restaurant,
    value: value
  };
}

export function removeOrderDishesAction(slug) {
  removeDishesFromLocalStorage(slug);
  return {
    type: REMOVE_ORDER_DISHES,
    payload: slug,
  };
}

export function removeCategoryDishesAction(slug, dishes) {
  removeCategoryDishesFromLocalStorage(slug, dishes);
  return {
    type: REMOVE_ORDER_CATEGORY_DISHES,
    payload: slug,
    dishes: dishes
  };
}

export function confirmedMakeOrderAction(order) {
  return {
    type: MAKE_ORDER,
    payload: order,
  };
}

export function confirmedGetOrdersAction(orders, total_pages, page) {
  return {
    type: GET_ORDERS,
    payload: orders,
    total_pages: total_pages,
    page: page
  };
}

export function confirmedGetGroupOrdersAction(orders) {
  return {
    type: GET_GROUP_ORDERS,
    payload: orders
  };
}

export function confirmedGetOrderAction(order) {
  return {
    type: GET_ORDER,
    payload: order
  };
}

export function handleDishOrderCountAction(reducerDishes, restaurant) {
  saveOrderDishesToLocalStorage({restaurant: restaurant, dishes: reducerDishes[restaurant.slug].dishes, company: reducerDishes[restaurant.slug].company});
  return {
    type: DISH_COUNT,
    payload: reducerDishes
  };
}

export function confirmedRequestWaiterFromOrderAction(table, orderId) {
  return {
    type: WAITER_REQEST,
    payload: table,
    orderId: orderId
  };
}

export function changeOrderBulkCheckoutAction(data) {
  return {
    type: CHANGE_ORDER_BULK_CHECKOUT,
    payload: data
  };
}

export function changeOrderCheckoutAction(field, value) {
  return {
    type: CHANGE_ORDER_CHECKOUT,
    field: field,
    value: value,
  };
}

export function approveOrderAction() {
  return {
    type: APPROVE_ORDER
  };
}

export function setOrderMessageAction(orderId, message) {
  return {
    type: SET_ORDER_MESSAGE,
    payload: message,
    orderId: orderId
  };
}

export function sendOrderMessageAction(orderId, message) {
  return (dispatch) => {
    return createOrderMessage(orderId, message).then((response) => {
      dispatch(setOrderMessageAction(orderId, response.data.message))
    }).catch((error) => {
      handleError(error.response.data.errors, error.response);
    });
  }
}

export function updateOrderMessageAction(orderId) {
  return (dispatch) => {
    updateOrderMessage(orderId).then((response) => {
      dispatch(updateOrderMessagesAction(response.data.messages))
    }).catch((error) => {
      handleError(error.response.data.errors, error.response);
    });
  }
}

export function updateOrderMessagesAction(order, messages) {
  return {
    type: UPDATE_ORDER_MESSAGES,
    payload: messages,
    order: order
  };
}

export function updateActiveOrdersAction(orders) {
  return {
    type: UPDATE_ACTIVE_ORDERS,
    payload: orders
  };
}

export function changeDishOrderAction(dishOrder, order) {
  return {
    type: CHANGE_DISH_ORDER,
    payload: dishOrder,
    order: order,
  };
}

export function setActiveOrderAction(order) {
  saveActiveOrderInLocalStorage(order);
  return {
    type: SET_ACTIVE_ORDER,
    payload: order
  };
}