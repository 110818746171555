import React, {useState, useEffect} from 'react'
import {requestForToken, onMessageListener} from './firebase';
import {handleAlert} from "../services/AlertService";
import { useHistory } from "react-router-dom";

const Notification = () => {
  const [notification, setNotification] = useState({title: '', body: ''});
  const notify = () => handleAlert('info', notification?.title, notification?.body, 5000);
  const history = useHistory();

  useEffect(() => {
    requestForToken().catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }, []);

  useEffect(() => {
    if (notification?.title && !history.location.pathname.includes('/dashboard')) {
      notify()
    }
  }, [notification]);

  onMessageListener().then((payload) => {
    setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
  }).catch((err) => console.log('failed: ', err));

  return null;
};

export default Notification