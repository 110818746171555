import { applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
//import rootReducers from './reducers/Index';
import { reducer as reduxFormReducer } from 'redux-form';
import RestaurantsReducer from "./reducers/RestaurantsReducer";
import OrdersReducer from "./reducers/OrdersReducer";
import AdminOrdersReducer from "./reducers/admin/OrdersReducer";

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: AuthReducer,
    restaurants: RestaurantsReducer,
    orders: OrdersReducer,
    admin: combineReducers({
        orders: AdminOrdersReducer
    })
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
