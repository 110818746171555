import { handleError } from './AlertService';
import axiosInstance from "./AxiosInstance";

// export function getRoutes(start_point, end_point) {
//   return axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/get_routes`, {params: {start_point, end_point}});
// }

export function getRestaurants(start_point, end_point) {
  return axiosInstance.get(`/api/route_restaurants`, {params: {start_point, end_point}});
}

export function getCompany(slug) {
  return axiosInstance.get(`/api/companies/${slug}`);
}

export function searchRestaurant(name) {
  return axiosInstance.get(`/api/restaurants/search`, {params: {name: name}});
}

// export function searchCity(name) {
//   //return axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/get_city`, {params: {name: name}});
//   return axiosInstance.get('https://autosuggest.search.hereapi.com/v1/autosuggest',
//     {params: {q: name, apiKey: process.env.REACT_APP_HERE_JS_KEY, in: 'countryCode:UKR', at: '50,25'}});
// }

export function requestWaiter(slug, table_id, checkRequest, type, waiterRequestText) {
  const postData = { table_id: table_id, check_request: checkRequest, check_request_type: type,
    waiter_request_text: waiterRequestText};
  return axiosInstance.post(`/api/restaurants/${slug}/waiter_request`, postData);
}

export function askService(slug, serviceId, tableId, message) {
  const postData = { service_id: serviceId, table_id: tableId, message: message};
  return axiosInstance.post(`/api/restaurants/${slug}/request_service`, postData);
}

export function divideCheck(payChecks, table_id) {
  const postData = { divided_checks: payChecks, table_id: table_id};
  return axiosInstance.post(`/api/pay_checks/divide`, postData);
}

export function getPayCheck(restaurant) {
  const postData = { table_id: restaurant.table.id, restaurant_id: restaurant.id};
  return axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/pay_checks`, {params: postData});
}

export function getRestaurantsInArea(latitude, longitude, radius) {
  const config = { params: {location: latitude ? [latitude, longitude] : ['', ''], radius: radius} };
  return axiosInstance.get(`/api/area_restaurants`, config);
}

export function getRestaurantsInBounds(bounds) {
  const config = { params: {bounds: bounds} };
  return axiosInstance.get(`/api/area_restaurants`, config);
}

export function getRestaurant(restaurant_id, table_id, fromMenu) {
  const params = { table_id: !!table_id ? table_id : '', menu: fromMenu };
  return axiosInstance.get(`/api/restaurants/${restaurant_id}`, {params: params});
}

export function getMenu(restaurant_id) {
  return axiosInstance.get(`/api/dishes`, {params: {restaurant_id}});
}

export function getRestaurantReviews(restaurant) {
  return axiosInstance.get(`/api/reviews`, {params: {restaurant_id: restaurant.id}});
}

export function getRestaurantImages(restaurant) {
  return axiosInstance.get(`/api/restaurants/${restaurant.id}/images`);
}

export function getCategoryDishes(restaurant, category, withPhoto) {
  return axiosInstance.get(`/api/dishes/category_dishes`,
    {params: {restaurant_id: restaurant.id, category_id: category.id, with_photo: withPhoto}});
}

export function getCategory(restaurant, category) {
  return axiosInstance.get(`/api/category_dishes/${category.id}`, {params: {restaurant_id: restaurant.id}});
}

export function createReview(restaurant_id, comment, rating) {
  const postData = { restaurant_id, comment, rating };
  return axiosInstance.post(`/api/reviews`, postData);
}

export function saveRateDish(dish, kind) {
  const postData = {dish: {kind: kind}};
  return axiosInstance.post(`/api/dishes/${dish.id}/rate_dish`, postData);
}

export function getRestaurantsCategories(params) {
  return axiosInstance.get(`/api/restaurants/categories`, {params: {restaurants: params}});
}

export function dishSearch(restaurant_id, search) {
  return axiosInstance.get(`/api/dishes/search_dish`, {params: {restaurant_id: restaurant_id, search: search}});
}

export function createRestaurant(parameters) {
  return axiosInstance.post(`/api/restaurants`, parameters);
}

export function getRestaurantTables(restaurant) {
  return axiosInstance.get(`/api/restaurants/${restaurant.id}/tables`);
}

export function formatError(errorResponse) {
  handleError(errorResponse.errors, errorResponse)
}

export function formatReviews(reviewsData) {
  return reviewsData.reviews;
}
