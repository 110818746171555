import {formatErrorRegister, formatErrorLogin, login, runLogoutTimer, saveTokenInLocalStorage, signUp,
  submitProfile, saveProfileInLocalStorage, submitForgotPassword, updateForgotPassword, submitTelegram,
  telegramLogin, destroyPhotoProfile, destroyProfile, authorizeAccess
} from '../../services/AuthService';
import {handleAlert, handleError} from "../../services/AlertService";
import {store} from '../store';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_PROFILE_ACTION = '[Update profile action] Update profile action';
export const DELETE_PROFILE_ACTION = '[DELETE_PROFILE_ACTION action] DELETE_PROFILE_ACTION action';
export const SWITCH_THEME = '[SWITCH_THEME action] SWITCH_THEME action';
export const USER_LOCATION = '[USER_LOCATION action] USER_LOCATION action';

export function signupAction(userParams, history) {
  return (dispatch) => {
    signUp(userParams).then((response) => {
      saveTokenInLocalStorage(Object.assign(response.headers, response));
      runLogoutTimer(dispatch, parseInt(response.headers['expiry']));
      dispatch(confirmedSignupAction(Object.assign(response.headers, response)));
      window.location.href = '/';
    }).catch((error) => {
      const errorMessage = formatErrorRegister(error.response.data);
      dispatch(signupFailedAction(errorMessage));
    });
  };
}

export function logout(history) {
  localStorage.removeItem('userDetails');
  localStorage.removeItem('activeOrders');
  window.location.href = '/login';
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage(Object.assign(response.headers, response));
        runLogoutTimer(
          dispatch,
          parseInt(response.headers['expiry']),
          history,
        );
        const tokenDetailsString = localStorage.getItem('userDetails');
        dispatch(loginConfirmedAction(JSON.parse(tokenDetailsString)));
        window.location.href = response.data.path || '/';
      })
      .catch((error) => {
        const errorMessage = formatErrorLogin(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function submitProfileAction(profile, history) {
  return (dispatch) => {
    submitProfile(profile)
      .then((response) => {
        saveProfileInLocalStorage(response.data.profile);
        dispatch(updateProfileCompleteAction(response.data.profile));
        handleAlert('success', 'Gotovo', 'Профіль змінено')
      })
      .catch((error) => {
        handleError(error.response.data.errors, error.response);
      });
  };
}

export function switchThemeAction(theme) {
  return {
    type: SWITCH_THEME,
    payload: theme,
  };
}

export function destroyPhotoProfileAction() {
  return (dispatch) => {
    destroyPhotoProfile()
      .then((response) => {
        saveProfileInLocalStorage(response.data.profile);
        dispatch(updateProfileCompleteAction(response.data.profile));
        handleAlert('success', 'Gotovo', 'Профіль змінено')
      })
      .catch((error) => {
        handleError(error.response.data.errors, error.response);
      });
  };
}

export function deleteProfileAction(history) {
  return (dispatch) => {
    destroyProfile().catch(() => {
      logout(history);
      localStorage.clear();
      dispatch(deleteProfileCompleteAction());
      handleError(['Профіль Gotovo видалено']);
    });
  }
}

export function submitTelegramAction(telegram, history) {
  return (dispatch) => {
    submitTelegram(telegram).then((resp) => {
      if (resp.data.success) {
        saveProfileInLocalStorage(resp.data.user);
        dispatch(updateProfileCompleteAction(resp.data.user));
      } else {
        telegramLogin(telegram.id).then((response) => {
          saveTokenInLocalStorage(Object.assign(response.headers, response));
          runLogoutTimer(dispatch, parseInt(response.headers['expiry']), history);
          const tokenDetailsString = localStorage.getItem('userDetails');
          dispatch(loginConfirmedAction(JSON.parse(tokenDetailsString)));
          window.location.href = response.data.path || '/';
        }).catch((error) => {
          handleError(error.response.data.errors, error.response);
        });
      }
    }).catch((error) => {
      handleError(error.response.data.errors, error.response);
    });
  };
}

export function submitForgotPasswordAction(profile, history) {
  return () => {
    submitForgotPassword(profile)
      .then(() => {
        history.push("/login");
        handleAlert('success', 'Gotovo', 'Тепер відкрийте свою пошту і натисніть на посилання')
      })
      .catch((error) => {
        handleError(error.response.data.errors, error.response);
      });
  };
}

export function updateForgotPasswordAction(profile, history) {
  return () => {
    updateForgotPassword({password: profile.password, password_confirmation: profile.password_confirmation,
      reset_password_token: history.location.search.split('=')[1]})
      .then(() => {
        history.push("/login");
        handleAlert('success', 'Gotovo', 'Тепер ви можете зайти по новому паролю')
      })
      .catch((error) => {
        if (Array.isArray(error.response.data.errors)) {
          handleError(error.response.data.errors.full_messages, error.response);
        } else {
          handleError(error.response.data.errors.full_messages, error.response);
        }
      });
  };
}

export function authorizeAccessAction(history) {
  return (dispatch) => {
    authorizeAccess().then((response) => {
      saveTokenInLocalStorage(Object.assign(response.headers, response));
      runLogoutTimer(dispatch, parseInt(response.headers['expiry']), history);
      const tokenDetailsString = localStorage.getItem('userDetails');
      dispatch(loginConfirmedAction(JSON.parse(tokenDetailsString)));
      window.location.href = response.data.path || '/dashboard';
    }).catch((error) => {
      handleError(error.response.data.errors, error.response);
    });
  };
}

export function changeUserLocation(location) {
  const auth = store.getState().auth.auth;
  if (!!auth.idToken) {
    return (dispatch) => {
      let params = {};
      if (!!auth.userData.delivery_point.lat) {
        params['latitude'] = location.lat;
        params['longitude'] = location.lng;
      } else {
        params['latitude'] = location.lat;
        params['longitude'] = location.lng;
        params['delivery_point'] = location
      }
      submitProfile(params).then((response) => {
        saveProfileInLocalStorage(response.data.profile);
        dispatch(updateProfileCompleteAction(response.data.profile));
      })
    }
  } else {
    return {
      type: USER_LOCATION,
      payload: location,
    };
  }
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function updateProfileCompleteAction(user) {
  return {
    type: UPDATE_PROFILE_ACTION,
    payload: user,
  };
}

export function deleteProfileCompleteAction() {
  return {
    type: DELETE_PROFILE_ACTION
  };
}
