import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";

const Error500 = ({ error }) => {
  const { t } = useTranslation();
  const storeAuth = useSelector(store => store.auth);
  const [chunkError, setError] = useState(false);

  useEffect(() => {
    if (!!error && /Loading chunk [\d]+ failed/.test(error.message)) {
      setError(true);
    }
  }, [error]);

  return (
    <div className="text-center">
      <div className='card m-3'>
        <div className='card-body'>
          { chunkError ?
            <>
              <h1>{t('errors.versionUpdated')}</h1>
              <h4>{t('errors.chunkError')}</h4>
            </>
          :
            <>
              <h1><i className="fa fa-times-circle text-danger fs-35" />{" "}{t('errors.oops')}</h1>
              <h4>{t('errors.someError')}</h4>
            </>}
          <Button className='btn btn-primary' onClick={() => window.location.reload()}>
            {t('reload')}
          </Button>
          <h4 className='mt-2'>{t('or')}</h4>
          { storeAuth.auth.userData.role === 'restaurateur' ?
            <div><a href='/dashboard' className="btn btn-outline-primary">В панель замовлень</a></div>
          :
            <>
              <p>{t('errors.goToMainPage')}</p>
              <div><a href='/' className="btn btn-outline-primary">{t('components.login.toMainPage')}</a></div>
            </>}
        </div>
      </div>
    </div>
  );
};

export default Error500;