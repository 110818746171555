import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en.json';
import uaTranslation from './ua.json';

const resources = {en: {translation: enTranslation}, ua: {translation: uaTranslation}, ru: {translation: uaTranslation}};

const fallbackLanguage = 'ua';

const browserLanguage = localStorage.getItem('language');// || navigator.language.split('-')[0];

i18n.use(initReactI18next).init({resources, lng: !!resources[browserLanguage] ? browserLanguage : fallbackLanguage});

export default i18n;
