import {
  UPDATE_DASHBOARD_LIST, GET_ADMIN_ORDERS, GET_DELIVERY_ORDERS, GET_DELIVERY_ORDER, GET_MY_DELIVERY_ORDERS,
  GET_RESTAURANT_ORDERS, DELIVERY_TAKE_ORDER, UPDATE_DELIVERY_LIST, HANDLE_WAITER_REQUEST, SAVE_PAY_CHECK,
  DELETE_PAY_CHECK, GET_PAY_CHECKS, HANDLE_TABLES_DASHBOARD, GET_ADMIN_ORDER, CREATE_ORDER_MESSAGE, ORDER_MESSAGES_SEEN,
  ADD_DISH_STOP_LIST, HANDLE_TABLE_DASHBOARD, GET_RESTAURANT_PAY_CHECK, GET_MANAGER_ORDERS,
  GET_MANAGER_ORDER
} from "../../actions/admin/ActionTypes";

const initialState = {
  recentOrders: {},
  deliveryOrders: {},
  myDeliveryOrders: {},
  restaurantOrders: {},
  restaurant: {rooms: {}, dashboard_tabs: {order_types: {}}},
  total_pages: 1,
  currentPage: 1,
  payChecks: {},
  total_amount: 0,
  last_pay: ''
};

export default function AdminOrdersReducer(state = initialState, actions) {

  if (actions.type === GET_ADMIN_ORDERS) {
    if (!!actions.restaurant) {
      return {
        ...state,
        recentOrders: actions.payload,
        restaurant: actions.restaurant
      };
    } else {
      return {
        ...state,
        recentOrders: actions.payload
      };
    }
  }

  // if (actions.type === GET_MANAGER_ORDERS) {
  //     return {
  //       ...state,
  //       recentOrders: actions.payload
  //     };
  // }
  //
  // if (actions.type === GET_MANAGER_ORDER) {
  //     return {
  //       ...state,
  //       recentOrders: {
  //         ...state.recentOrders,
  //         [actions.payload.id]: actions.payload
  //       }
  //     };
  // }

  if (actions.type === GET_ADMIN_ORDER) {
    let recentOrders = state.recentOrders;
    if (actions.payload.status === "declined_by_restaurant" || actions.payload.status === "completed" ||
        actions.payload.status === "refunded" || actions.payload.status === "declined_by_user") {
      delete recentOrders[actions.payload.id]
    } else {
      recentOrders = Object.assign(recentOrders, {[actions.payload.id]: actions.payload})
    }
    if (!!actions.restaurant) {
      return {
        ...state,
        recentOrders: recentOrders,
        restaurant: actions.restaurant
      };
    } else {
      return {
        ...state,
        recentOrders: recentOrders
      };
    }
  }

  if (actions.type === UPDATE_DASHBOARD_LIST) {
      return {
        ...state,
        recentOrders: actions.payload,
        deliveryOrders: actions.payload
      };
  }

  if (actions.type === UPDATE_DELIVERY_LIST) {
    return {
      ...state,
      deliveryOrders: actions.payload
    };
  }

  if (actions.type === GET_DELIVERY_ORDERS) {
    return {
      ...state,
      deliveryOrders: actions.payload
    };
  }

  if (actions.type === GET_MY_DELIVERY_ORDERS) {
    return {
      ...state,
      myDeliveryOrders: actions.payload,
      total_pages: actions.total_pages,
      currentPage: actions.page,
      total_amount: actions.total_amount,
      last_pay: actions.last_pay
    };
  }

  if (actions.type === GET_RESTAURANT_ORDERS) {
    return {
      ...state,
      restaurantOrders: actions.payload,
      total_pages: actions.total_pages,
      currentPage: actions.page
    };
  }

  if (actions.type === GET_DELIVERY_ORDER) {
    return {
      ...state,
      myDeliveryOrders: {
        ...state.myDeliveryOrders,
        [actions.payload.id]: actions.payload
      }
    };
  }

  if (actions.type === DELIVERY_TAKE_ORDER) {
    return {
      ...state,
      myDeliveryOrders: {
        ...state.myDeliveryOrders,
        [actions.payload.id]: actions.payload
      }
    };
  }

  if (actions.type === HANDLE_WAITER_REQUEST) {
    return {
      ...state,
      restaurant: {
        ...state.restaurant,
        tables: {
          ...state.restaurant.tables,
          [actions.payload.id]: actions.payload
        }
      }
    };
  }

  if (actions.type === HANDLE_TABLES_DASHBOARD) {
    return {
      ...state,
      restaurant: {
        ...state.restaurant,
        tables: actions.payload
      }
    };
  }

  if (actions.type === HANDLE_TABLE_DASHBOARD) {
    return {
      ...state,
      restaurant: {
        ...state.restaurant,
        tables: {
          ...state.restaurant.tables,
          [actions.payload.id]: actions.payload
        }
      }
    };
  }

  if (actions.type === SAVE_PAY_CHECK) {
    return {
      ...state,
      restaurant: {
        ...state.restaurant,
        tables: actions.payload
      }
    };
  }

  if (actions.type === DELETE_PAY_CHECK) {
    return {
      ...state,
      restaurant: {
        ...state.restaurant,
        tables: actions.payload
      }
    };
  }

  if (actions.type === GET_PAY_CHECKS) {
    return {
      ...state,
      payChecks: actions.payload,
      total_pages: actions.totalPages,
      total_amount: actions.totalAmount
    };
  }

  if (actions.type === GET_RESTAURANT_PAY_CHECK) {
    return {
      ...state,
      payChecks: {
        ...state.payChecks,
        [actions.payload.id]: actions.payload
      }
    };
  }

  if (actions.type === CREATE_ORDER_MESSAGE) {
    return {
      ...state,
      recentOrders: {
        ...state.recentOrders,
        [actions.order]: {
          ...state.recentOrders[actions.order],
          messages: {
            ...state.recentOrders[actions.order].messages,
            [actions.payload.id]: actions.payload
          }
        }
      }
    };
  }

  if (actions.type === ORDER_MESSAGES_SEEN) {
    return {
      ...state,
      recentOrders: {
        ...state.recentOrders,
        [actions.payload]: {
          ...state.recentOrders[actions.payload],
          messages: actions.messages
        }
      }
    };
  }

  if (actions.type === ADD_DISH_STOP_LIST) {
    return {
      ...state,
      recentOrders: {
        ...state.recentOrders,
        [actions.orderId]: {
          ...state.recentOrders[actions.orderId],
          dishes: {
            ...state.recentOrders[actions.orderId].dishes,
            [actions.payload.dish_order_id]: actions.payload
          }
        }
      }
    };
  }

  return state;
}
