import React, { createContext, useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import {setStoreAuth} from "../services/AuthService";
import {switchThemeAction} from "../store/actions/AuthActions";

export const ThemeContext = createContext();
const ThemeContextProvider = (props) => {
  const dispatch = useDispatch();
  const body = document.querySelector("body");
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setStoreAuth(dispatch, props.history);
    const body = document.querySelector("body");
    let resizeWindow = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    body.setAttribute("data-sidebar-style", "overlay");
    if (!!tokenDetails && !!tokenDetails.userData) {
      body.setAttribute("data-theme-version", tokenDetails.userData.theme);
    } else if (systemPrefersDark) {
      body.setAttribute("data-theme-version", 'dark');
      dispatch(switchThemeAction('dark'));
    } else {
      body.setAttribute("data-theme-version", 'light');
      dispatch(switchThemeAction('light'));
    }
    body.setAttribute("data-layout", "vertical");
    body.setAttribute("data-nav-headerbg", "color_3");
    body.setAttribute("data-headerbg", "color_1");
    body.setAttribute("data-sidebar-style", "overlay");
    body.setAttribute("data-sibebarbg", "color_3");
    body.setAttribute("data-primary", "color_1");
    body.setAttribute("data-sidebar-position", "fixed");
    body.setAttribute("data-header-position", "fixed");
    body.setAttribute("data-container", "wide");
    body.setAttribute("direction", "ltr");
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, [dispatch, props.history]);

  return (
    <ThemeContext.Provider
      value={{
        body,
        windowWidth,
        windowHeight
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
