import {loginConfirmedAction, logout} from '../store/actions/AuthActions';
import {handleError} from './AlertService';
import axiosInstance from "./AxiosInstance";

export function signUp(userParams) {
    return axiosInstance.post(`/api/auth`, userParams);
}

export function telegramLogin(id) {
    return axiosInstance.post(`/api/telegram/login`, {telegram_id: id}, {removeHeaders: true});
}

export function appleLogin(id) {
    return axiosInstance.post(`/api/apple/login`, {id_token: id}, {removeHeaders: true});
}

export function login(email, password) {
    const postData = {email, password};
    return axiosInstance.post(`/api/auth/sign_in`, postData);
}

export function submitForgotPassword(email) {
    const postData = { email, redirect_url: `${window.location.protocol}//${window.location.host}/password` };
    return axiosInstance.post(`/api/auth/password`, postData);
}

export function updateForgotPassword(params) {
    return axiosInstance.put(`/api/auth/password`, params);
}

export function getProfile() {
    return axiosInstance.get(`/api/profile`);
}

export function submitProfile(profile) {
    if (profile.pictureForUpload) {
        var formData = new FormData();
        formData.append("profile[picture]", profile.pictureForUpload);
        formData['headers'] = {'Content-Type': 'multipart/form-data' };
    } else {
        delete profile["picture"]
    }
    return axiosInstance.put(`/api/profile`, profile.pictureForUpload ? formData : {profile: profile});
}

export function destroyPhotoProfile() {
    return axiosInstance.delete(`/api/profile`);
}

export function destroyProfile() {
    return axiosInstance.delete(`/api/profile/destroy_profile`);
}

export function submitTelegram(telegram) {
    return axiosInstance.put(`/api/telegram/update_user`, {telegram: telegram});
}

export function submitApple(apple) {
    return axiosInstance.put(`/api/apple/update_user`, {apple: apple});
}

export function submitShift(shift) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const params = { restaurant_id: tokenDetails.restaurant ? tokenDetails.restaurant.id : '', working: shift};
    return axiosInstance.put(`/api/management/settings/restaurant_stuffs/shift`, params);
}

export function authorizeAccess() {
    return axiosInstance.get(`/api/authorize_access`);
}

export function sendPushToken(token, device) {
    const params = {user_device: {token: token, device_type: device}};
    return axiosInstance.post(`/api/user_devices`, params);
}

export function formatErrorRegister(errorResponse) {
    handleError(errorResponse.errors.full_messages, errorResponse);
}

export function formatErrorLogin(errorResponse) {
    handleError(errorResponse.errors, errorResponse)
}

export function saveTokenInLocalStorage(response) {
    localStorage.setItem('userDetails',
      JSON.stringify({
          idToken: response['access-token'],
          client: response['client'],
          uid: response['uid'],
          expireDate: parseInt(response['expiry']) * 1000,
          userData: response.data.user,
          restaurant: response.data.restaurant || (!!response.data.restaurants && response.data.restaurants[0]),
          restaurants: response.data.restaurants,
      }));
}

export function saveProfileInLocalStorage(profile) {
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    userDetails.userData = profile;
    userDetails.uid = profile.email;
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
}

export function saveActiveOrderInLocalStorage(order) {
    const oldActiveOrders = JSON.parse(localStorage.getItem('activeOrders')) || {};
    const mergedActiveOrders = Object.assign(oldActiveOrders, {[order.id]: order});
    localStorage.setItem('activeOrders', JSON.stringify(mergedActiveOrders));
}

// export function shouldAskOrdersInRequest() {
//     return !!JSON.parse(localStorage.getItem('activeOrders'));
// }

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(logout(history));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();
    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history);
}

export function setStoreAuth(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    if (!!tokenDetails && !!tokenDetails.idToken) {
        const expireDate = new Date(tokenDetails.expireDate);
        const todaysDate = new Date();
        if (todaysDate > expireDate) {
            dispatch(logout(history));
            return;
        }
        dispatch(loginConfirmedAction(tokenDetails));

        const timer = expireDate.getTime() - todaysDate.getTime();
        runLogoutTimer(dispatch, timer, history);
    }
}
