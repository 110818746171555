import React, { useState } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Components
import Alerts from "../components/chatBox/Alerts";
import { Link } from "react-router-dom";

const ChatBox = ({ onClick, toggle }) => {
   const [toggleTab, settoggleTab] = useState(
      window.location.hash.slice(1) ? window.location.hash.slice(1) : "alerts"
   );

   const dataToggle = [
      // { href: "#notes", name: "Notes" },
      { href: "#alerts", name: "Alerts", display: "Замовлення" },
      // { href: "#chat", name: "Chat" },
   ];

   return (
      <div className={`chatbox ${toggle === "chatbox" ? "active" : ""}`}>
         <div className="chatbox-close" onClick={() => onClick()}/>
         <div className="custom-tab-1">
            {/*<ul className="nav nav-tabs">*/}
            {/*   {dataToggle.map((data, i) => (*/}
            {/*      <li className="nav-item" key={i}>*/}
            {/*         <Link*/}
            {/*            className={`nav-link ${*/}
            {/*               toggleTab === data.name.toLocaleLowerCase()*/}
            {/*                  ? "active"*/}
            {/*                  : ""*/}
            {/*            }`}*/}
            {/*            to="#"*/}
            {/*            data-toggle="tab"*/}
            {/*            href={data.href}*/}
            {/*            onClick={() =>*/}
            {/*               settoggleTab(data.name.toLocaleLowerCase())*/}
            {/*            }*/}
            {/*         >*/}
            {/*            {data.display}*/}
            {/*         </Link>*/}
            {/*      </li>*/}
            {/*   ))}*/}
            {/*</ul>*/}
            <div className="tab-content">
               <Alerts
                  PerfectScrollbar={PerfectScrollbar}
                  toggle={toggle}
                  toggleTab={toggleTab}
               />
            </div>
         </div>
      </div>
   );
};

export default ChatBox;
